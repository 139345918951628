/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import Layout from "../../../../Layouts/Layout";
import SettlementTimeSection from "../../../../../components/Sections/Settlement/SettlementTimeSection/SettlementTimeSection";
import LpDismissalCheck24Meta from "./LpDismissalCheck24Meta";
import PartnerLogoSection from "../../../../../components/Sections/Generic/PartnerLogoSection/PartnerLogoSection";
import TrustSection from "../../../../../components/Sections/Generic/TrustSection/TrustSection";
import NoWinNoFeeSection from "../../../../../components/Sections/Settlement/NoWinNoFeeSection/NoWinNoFeeSection";
import LandingPageTeaser from "../../../../../components/Teaser/LandingPageTeaser";
import HowItWorksSection from "../../../../../components/Sections/Settlement/HowItWorksSection/HowItWorksSection";
import PartnersSection from "../../../../../components/Sections/Generic/PartnersSection/PartnersSection";
import TeamSection from "../../../../../components/Sections/Generic/TeamSection/TeamSection";
import GreyBackgroundWrapper from "../../../../../components/BackgroundWrapper/GreyBackgroundWrapper";
import LinkedCardBox from "../../../../../components/LinkedCardBox/LinkedCardBox";
import { settlementChoiceCards } from "../../../../../services/landingPageService";
import Container from "../../../../../components/Container/Container";
import { useCustomer } from "../../../../../provider/Customer/CustomerProvider";

const LpDismissalCheck24 = () => {
  const { isLoggedIn } = useCustomer();

  return (
    <Layout minimal={!isLoggedIn} clickableLogo={isLoggedIn} backgroundType={"landingpage"}>
      <LpDismissalCheck24Meta />
      <LandingPageTeaser headline={"Abfindung bei Kündigung"} subheadline={"Wie können wir Ihnen helfen?"} hasTeaserButton={false}>
        <LinkedCardBox linkedCards={settlementChoiceCards} />
      </LandingPageTeaser>
      <PartnerLogoSection containerStyle={"settlementContainer"} partnerName={"check24"} />
      <GreyBackgroundWrapper>
        <HowItWorksSection />
        <NoWinNoFeeSection />
      </GreyBackgroundWrapper>
      <SettlementTimeSection />
      <TeamSection headline={"Sie möchten professionell gegen Ihre Kündigung angehen?"} subHeadline={"Wir kümmern uns persönlich um Sie"} />
      <TrustSection />
      <Container>
        <LinkedCardBox linkedCards={settlementChoiceCards} />
      </Container>
      <PartnersSection />
    </Layout>
  );
};

export default LpDismissalCheck24;
