/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import TextCard from "../../Cards/TextCard/TextCard";
import Container from "../../Container/Container";
import TextContainer from "../../Container/TextContainer";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import CrossIcon from "../../../assets/icons/common/toggleable/IconClose";
import { Box } from "@mui/material";
import { useCheckoutOrder } from "../../../provider/CheckoutOrder/CheckoutOrderProvider";

const PaymentFailed = () => {
  const { checkoutOrder } = useCheckoutOrder();
  return (
    <Container>
      <TextCard boxHeading={"Ihre Bezahlung ist fehlgeschlagen"} headingColor={"primary"} minHeadingHeight={0}>
        <TextContainer>
          <CrossIcon sx={{ width: 100, height: 100 }} />
          <Box textAlign={"center"}>Leider ist Ihre Bezahlung fehlgeschlagen. Bitte versuchen Sie es erneut oder wählen Sie eine andere Zahlart aus.</Box>
          <Button fullWidth variant={"contained"} color="primary" component={Link} to={"/checkout?orderId=" + checkoutOrder.orderIdentifier}>
            Zahlart auswählen
          </Button>
        </TextContainer>
      </TextCard>
    </Container>
  );
};

export default PaymentFailed;
