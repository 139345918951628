import React, { ReactNode, useEffect } from "react";
import { checkToken } from "../../services/localStorage";
import { logout } from "../../store/login/actions";
import { Navigate, useLocation } from "react-router";
import { useCustomer } from "../../provider/Customer/CustomerProvider";

type RequireAuthProps = {
  children: ReactNode;
  redirectTo?: string;
};

export default function RequireAuth({ children, redirectTo = "/login" }: RequireAuthProps) {
  const { customer, isLoading } = useCustomer();
  const location = useLocation();
  const isAuthenticated = checkToken(localStorage.getItem("token"), customer);
  useEffect(() => {
    if (!!customer && !isAuthenticated) {
      logout();
    }
  }, [customer, isAuthenticated]);

  if (!customer && isLoading) {
    return null;
  }

  return isAuthenticated ? <>{children}</> : <Navigate to={redirectTo} state={{ from: location }} />;
}
