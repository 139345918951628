import React from "react";
import VehicleSpecificCaseDetails from "./ContractSpecificCaseDetails/VehicleSpecificCaseDetails";
import PurchaseSpecificCaseDetails from "./ContractSpecificCaseDetails/PurchaseSpecificCaseDetails";
import TravelSpecificCaseDetails from "./ContractSpecificCaseDetails/TravelSpecificCaseDetails";
import SubscriptionSpecificCaseDetails from "./ContractSpecificCaseDetails/SubscriptionSpecificCaseDetails";
import MembershipSpecificCaseDetails from "./ContractSpecificCaseDetails/MembershipSpecificCaseDetails";
import ServiceSpecificCaseDetails from "./ContractSpecificCaseDetails/ServiceSpecificCaseDetails";
import EventSpecificCaseDetails from "./ContractSpecificCaseDetails/EventSpecificCaseDetails";
import ProviderSpecificCaseDetails from "./ContractSpecificCaseDetails/ProviderSpecificCaseDetails";
import DataLeakSpecificCaseDetails from "./ContractSpecificCaseDetails/DataLeakSpecificCaseDetails";
import { AbstractCase } from "../../../../types/Entities/AbstractCase";

type ContractSpecificDetailsSectionProps = {
  contractLawCase: AbstractCase;
};

const ContractSpecificDetailsSection = ({ contractLawCase }: ContractSpecificDetailsSectionProps) => {
  switch (contractLawCase.name) {
    case "vehicleContract":
      return <VehicleSpecificCaseDetails contractLawCase={contractLawCase} />;
    case "purchaseContract":
      return <PurchaseSpecificCaseDetails contractLawCase={contractLawCase} />;
    case "travelContract":
      return <TravelSpecificCaseDetails contractLawCase={contractLawCase} />;
    case "subscriptionContract":
      return <SubscriptionSpecificCaseDetails contractLawCase={contractLawCase} />;
    case "membershipContract":
      return <MembershipSpecificCaseDetails contractLawCase={contractLawCase} />;
    case "serviceContract":
      return <ServiceSpecificCaseDetails contractLawCase={contractLawCase} />;
    case "eventContract":
      return <EventSpecificCaseDetails contractLawCase={contractLawCase} />;
    case "providerContract":
      return <ProviderSpecificCaseDetails contractLawCase={contractLawCase} />;
    case "dataLeakContract":
      return <DataLeakSpecificCaseDetails contractLawCase={contractLawCase} />;
    default:
      return null;
  }
};

export default ContractSpecificDetailsSection;
