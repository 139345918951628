/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";

import termsOfServiceStyle from "../termsOfServiceStyle";
import { HashLink as Link } from "react-router-hash-link";
import {
  LAW_FIRM_EMAIL,
  SERVICE_PHONE,
  LAW_FIRM_FAX,
  LAW_FIRM_NAME,
  LAW_FIRM_ADDRESS,
  LAW_FIRM_POSTAL_CODE,
  LAW_FIRM_ADDRESS_LOCALITY,
} from "../../../services/globalVars";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

const TermsOfServiceLawFirm = () => {
  let number = 0;
  const counter = () => {
    number++;
    return number;
  };

  return (
    <>
      <Box sx={{ ...termsOfServiceStyle.termsOfServicePage, ...termsOfServiceStyle.scrollToAnchor }} id="mandatsbedingungen">
        <br />
        <br />
        <br />
        <Typography variant={"h1"} sx={termsOfServiceStyle.title}>
          Allgemeine Geschäfts- und Mandatsbedingungen
        </Typography>
        Für alle – auch zukünftigen – Verträge zwischen dem oder den Mandanten / der Mandantin (im Folgenden der
        <b> "Mandant"</b>) und der Legalbird Rechtsanwälte Rechtsanwalts GmbH (im Folgenden die <b>Kanzlei</b>, zusammen mit dem Mandanten die „Parteien“), die
        eine Vertretung in Rechtsangelegenheiten oder rechtliche Beratungen zum Gegenstand haben, vereinbaren die Parteien die folgenden Allgemeinen Geschäfts-
        und Mandatsbedingungen.
        <Typography variant={"h3"}>{counter()} Allgemeine Bedingungen</Typography>
        <p>
          <strong>
            Bei rechtsschutzversicherten Kunden werden die anfallen Kosten i.d.R. gemäß der individuellen Versicherungsbedingungen von der
            Rechtsschutzversicherung getragen.
          </strong>
        </p>
        <ol>
          <li>
            Die beauftragte Kanzlei erbringt die anwaltliche Tätigkeit am Kanzleisitz. Der Kanzleisitz ist Erfüllungsort gemäß § 362 Bürgerliches Gesetzbuch.
            Der Mandant hat die Vergütung an diesem Sitz zu zahlen.
          </li>
          <li>
            Die Haftung der beauftragten Kanzlei wird für Fälle einfacher Fahrlässigkeit auf 2.500.000 € begrenzt.
            <ul>
              <li>Die Haftung für Vorsatz und grobe Fahrlässigkeit bleibt unberührt.</li>
              <li>Im Einzelfall können bei einem höheren Haftungsrisiko Einzelfallversicherungen gesondert vereinbart werden.</li>
            </ul>
          </li>
          <li>Fernmündliche Erklärungen und Auskünfte der Kanzlei sind nicht verbindlich, es sei denn, sie sind von ihr schriftlich bestätigt worden.</li>
          <li>
            Der Mandant ist gemäß § 49b Abs. 5 BRAO darauf hingewiesen worden, dass sich in dieser Angelegenheit die Gebühren nach dem Gegenstandswert
            berechnen.
          </li>
          <li>
            Im Falle von <strong>Kündigungsschutzklagen</strong>: Die Kanzlei weist seinen Auftraggeber ausdrücklich darauf hin, dass der Auftraggeber in dem
            Rechtsstreit vor dem Arbeitsgericht in der ersten Instanz keinen Kostenerstattungsanspruch gegen die andere Prozesspartei hat. Die durch die
            Tätigkeit der Kanzlei entstandenen Gebühren und Auslagen erster Instanz trägt der Auftraggeber in jedem Fall selbst. Dies gilt auch bei einer
            außergerichtlichen Erledigung der Angelegenheit. Eine Kostenerstattung ist – je nach Ausgang des Verfahrens – erst ab der zweiten Instanz möglich.
          </li>
          <li>
            Die im vorgenannten Punkt genannten Gebühren werden bei Kündigungsschutzklagen im Rahmen einer Prozesskostenfinanzierung durch die Legalbird GmbH
            unter den in deren <Link to={"/agb-prozessfinanzierung"}>AGB</Link> genannten Bedingungen übernommen.
          </li>
          <li>
            Der Auftraggeber verpflichtet sich, zur Kommunikation mit der Kanzlei und zum Austausch von Dokumenten die sichere Übertragung über die Servicewelt
            der Plattform
            <a href="https://www.legalbird.de/servicewelt">Legalbird</a> zu nutzen. Er wird per E-Mail bei Eingang neuer Nachrichten in der Servicewelt
            benachrichtigt. Abweichende Kommunikationsmedien (z.B. per Post) sind gestattet, wenn dies gesetzlich so vorgesehen ist.
          </li>
          <li>Der Mandant ist mit diesen Bedingungen für alle der Kanzlei bereits erteilten und noch zu erteilenden Aufträgen einverstanden.</li>
        </ol>
        <Typography variant={"h3"}>{counter()} Zustandekommen des Mandatsvertrages</Typography>
        <ol>
          <li>
            Anfragen an die Kanzlei (per Brief, Fax, Email, Telefonat, Gespräch) sind für beide Parteien ebenso unverbindlich wie das Dienstleistungsangebot der
            Kanzlei auf der Internetseite unter www.legalbird.de. Ein Mandatsvertrag kommt erst zustande, wenn der Mandant die Kanzlei mit der Wahrnehmung
            seiner rechtlichen Interessen beauftragt und die Kanzlei erklärt, das Mandat zu übernehmen.
          </li>
          <li>
            Mit Klick auf "Vollmacht erteilen und kostenpflichtig beauftragen" werden die durch den Mandanten übermittelten Informationen an die Kanzlei
            weitergeleitet. Die Kanzlei verpflichtet den Kunden bei Zustandekommen eines Mandatsverhältnisses zur Entbindung von ihrer Schweigepflicht gegenüber
            Legalbird. Mit der Weiterleitung kommt noch kein Vertrag zwischen dem Kunden und der Kanzlei zustande. Es handelt sich lediglich um ein Angebot zum
            Abschluss eines Anwaltsvertrags. Der Mandant hat keinen Anspruch darauf, dass die Kanzlei die Anfrage zur anwaltlichen Beratung annimmt.
          </li>
          <li>
            Nimmt die Kanzlei das Angebot des Mandanten an, kommt ein Anwaltsvertrag zustande. Der Inhalt des Anwaltsvertrags wird allein vom Mandanten und der
            Kanzlei untereinander bestimmt. Die Kanzlei informiert den Kunden über die Annahme des Angebots per E-Mail.
          </li>
          <li>Ein Mandatsvertrag kommt dabei ausschließlich zwischen der Kanzlei und dem Mandanten zustande.</li>
        </ol>
        <Typography variant={"h3"}>{counter()} Umfang und Ausführung des Auftrags</Typography>
        <ol>
          <li>
            Die Kanzlei legt der Bearbeitung die vom Mandanten erteilten Auskünfte und vorgelegten Unterlagen und Dokumente als richtig, vollständig und
            ordnungsgemäß zugrunde. Die Beurteilung der Richtigkeit, Vollständigkeit und Ordnungsmäßigkeit der Auskünfte, Unterlagen und Dokumente ist nicht
            Gegenstand des erteilten Auftrags.
          </li>
          <li>Auch im Übrigen ist Gegenstand des Auftrags lediglich die vereinbarte Leistung, nicht ein bestimmter Erfolg.</li>
          <li>
            Die Kanzlei ist zur Einlegung von Rechtsmitteln und Rechtsbehelfen nur verpflichtet, wenn der Mandant einen hierauf gerichteten Auftrag erteilt und
            die Kanzlei diesen Auftrag angenommen hat. Hat die Kanzlei dem Mandanten einen diesbezüglichen Vorschlag unterbreitet und der Mandant binnen zwei
            Wochen hierzu nicht Stellung genommen, obwohl der Mandant auf die Bedeutung seines Schweigens hingewiesen worden ist, gilt dieses Schweigen als
            Zustimmung.
          </li>
          <li>
            Der Auftrag wird grundsätzlich allen Rechtsanwälten der Kanzlei erteilt. Zur Sachbearbeitung können auch freie Mitarbeiter oder sonstige, sorgfältig
            ausgewählte fachkundige Dritte herangezogen werden.
          </li>
          <li>Der Bearbeitung des Mandats wird ausschließlich deutsches Recht zugrunde gelegt.</li>
        </ol>
        <Typography variant={"h3"}>{counter()} Dokumentendienstleistungen</Typography>
        Die Kanzlei stellt über die in den vorherigen Abschnitten genannten Mandatsverhältnisse hinaus einen Dienst für die die Erstellung und den Erwerb
        hochwertiger, rechtssicherer Dokumente zur Verfügung (<b>"Dokumentendienstleistung"</b>), für welche die in dieser Ziffer folgenden Regelungen gelten:
        <Typography variant={"h4"}>{number}.1 Leistungen der Kanzlei</Typography>
        <ol>
          <li>
            Die Kanzlei schuldet lediglich die Bereitstellung der im Rahmen der Dokumentendienstleistungen vertraglich vereinbarten digitalen Inhalte zur
            automatischen Vorbereitung von Dokumenten sowie deren Hinterlegung, nicht jedoch einen mit den Dokumenteninhalten von Kunden beabsichtigten Erfolg.
          </li>
          <li>
            Der jederzeitige ordnungsgemäße Betrieb bzw. die ununterbrochene Nutzbarkeit bzw. Erreichbarkeit der Plattform ist nicht von der Kanzlei geschuldet,
            weder in Bezug auf Ausfallzeiten oder Qualitätseinschränkungen durch Wartung und Software-Updates oder Zeiten, in denen die Plattform aufgrund von
            technischen oder sonstigen Problemen, die nicht im Einflussbereich von der Kanzlei liegen (höhere Gewalt, Verschulden Dritter etc.), über das
            Internet nicht zu erreichen sind.
          </li>
          <li>
            Die ordnungsgemäße und rechtswirksame Verwendung der Dokumenteninhalte obliegt eigenverantwortlich dem Mandanten und ist nicht von den
            Leistungspflichten der Kanzlei umfasst.
          </li>
          <li>
            Die Kanzlei weist ausdrücklich darauf hin, dass die Dokumentendienstleistungen{" "}
            <strong>ausschließlich auf Basis der vom Mandant gemachten Angaben</strong> durchgeführt wurden und eine{" "}
            <strong>persönliche Beratung durch einen Rechtsanwalt nicht ersetzen</strong>. Die darin enthaltenen Formulierungen erheben daher keinen Anspruch
            auf uneingeschränkte Rechtsgültigkeit, weil durchaus eine andere Rechtsansicht vorliegen, im Rahmen des automatisierten Interviewprozesses nicht
            jeder Sachverhalt mit seinen spezifischen Besonderheiten erfasst werden und die einschlägige Rechtsprechung sich ändern kann. Für die Erstellung von
            Dokumenten, die über den in den Dokumentendienstleistungen angebotenen Individualisierungsgrad hinausgehen, empfiehlt die Kanzlei ausdrücklich eine
            persönliche Individualberatung durch einen Rechtsanwalt.
          </li>
        </ol>
        <Typography variant={"h4"}>{number}.2 Zustandekommen des Vertrages</Typography>
        <p>
          Der Vertrag zwischen der Kanzlei und dem Kunden über die Nutzung der Dokumentendienstleistungen kommt durch Klicken auf "Jetzt für{" "}
          <i>[Betrag in EUR]</i> kaufen"-Button zustande. Die Eröffnung eines kostenlosen Nutzerkontos auf der Legalbird Website ist hierfür Voraussetzung.
          Dieser Bestellvorgang kann jederzeit im Falle von Eingabefehlern abgebrochen werden. Der Kunde hat vor Abschluss der Bestellung die Möglichkeit
          Eingabefehler auszubessern.
        </p>
        <Typography variant={"h4"}>{number}.3 Haftung</Typography>
        <p>
          Die Dokumenteninhalte werden auf Grundlage der Angaben des Mandanten erstellt. Die Kanzlei ist nicht für die Richtigkeit der vom Mandanten
          eingegebenen Daten verantwortlich und übernimmt für diese auch keine Haftung.
        </p>
        <Typography variant={"h3"}>{counter()} Kommunikation & Fallbearbeitung</Typography>
        <ol>
          <li>
            Die Kanzlei nutzt zur effizienten Fallbearbeitung und Kommunikation mit dem Mandaten die Software (nachfolgend <b>"Plattform"</b>) der Legalbird
            GmbH (nachfolgend <b>"Plattformbetreiber"</b>).
          </li>
          <li>
            Der Mandant stimmt der Kommunikation via Plattform, Email, Telefon, besonderem elektronischem Anwaltspostfach (beA) und Telefax zu. Dies gilt auch
            für den Verkehr zwischen der Kanzlei und Dritten im Zusammenhang mit der Bearbeitung des Mandats. Der Mandant ruft Nachrichten mindestens einmal pro
            Tag ab und sorgt dafür, dass unbefugte Dritte keinen Zugriff auf die Nachrichten haben. Für technisch bedingte Verzögerungen oder
            Nicht-Übermittlungen von Nachrichten kann die Kanzlei unabhängig vom Versandwege leider keine Haftung übernehmen.
          </li>
          <li>
            Die Kanzlei stellt über die Plattform einen internen Nutzungsbereich für den Mandanten bereit, über den auch Dokumente und andere Informationen zum
            laufenden Mandat bereitgestellt werden. Der Mandant prüft diesen Nutzungsbereich regelmäßig und aktualisiert hinterlegte Daten bei Veränderung.
          </li>
          <li>
            Der Mandant teilt der Kanzlei eine Änderung der eigenen Adress- und Kommunikationsdaten unverzüglich mit. Gleiches gilt für Abwesenheitszeiten,
            während derer der Mandant nicht zu erreichen ist. Die vom Mandanten bekannt gegebenen Adress- und Kommunikationsdaten gelten bis zu einer Mitteilung
            an die Kanzlei über ihre Änderung als zutreffend.
          </li>
        </ol>
        <Typography variant={"h3"}>{counter()} Abrechnung gemäß der gesetzlichen Vorgaben</Typography>
        <ol>
          <li>
            Die zu entrichtende Vergütung richtet sich entsprechend den gesetzlichen Vorgaben des Rechtsanwaltsvergütungsgesetzes (RVG) nach dem Gegenstandswert
            der Angelegenheit, sofern nicht ausdrücklich schriftlich etwas Abweichendes vereinbart wird.
          </li>
          <li>
            Im Falle der Bewilligung von Beratungshilfe, sind mit dieser alle Kosten der Kanzlei abgegolten, die im Zusammenhang mit der rechtlichen
            Angelegenheit, für die Beratungshilfe bewilligt wurde, gegenüber dem Mandanten entstehen. Die Kanzlei darf darüber hinaus keine weiteren Kosten in
            Rechnung stellen.
          </li>
          <li>
            Kostenerstattungsansprüche und andere Ansprüche des Mandanten gegen den Gegner, die Justizkasse oder sonstige erstattungspflichtige Dritte werden in
            Höhe der Kostenansprüche an die Kanzlei abgetreten, mit der Ermächtigung, die Abtretung dem Zahlungspflichtigen mitzuteilen. Die Kanzlei nimmt die
            Abtretung an.
          </li>
          <li>
            An die Kanzlei geleistete Zahlungen Dritter (Gegner, Rechtsschutzversicherung, Sonstige) verwahrt die Kanzlei bis zum Abschluss des Mandats als
            Fremdgeld für den Mandanten, soweit die Kanzlei sie nicht mit offenen Ansprüchen auf Vergütung, Auslagen und Zinsen aus derselben oder einer anderen
            Rechtssache des Mandanten verrechnet.
          </li>
          <li>Mehrere Mandanten in einer Rechtssache sind Gesamtschuldner der Kostenansprüche der Kanzlei.</li>
        </ol>
        <Typography variant={"h3"}>{counter()} Pflichten des Mandanten</Typography>
        <ol>
          <li>
            Die Kanzlei kann den Auftrag des Mandanten nur dann ordnungsgemäß bearbeiten, wenn der Mandant entsprechend mitwirkt. Der Mandant übergibt der
            Kanzlei über die Plattform daher unaufgefordert alle für die Ausführung des Auftrages notwendigen Unterlagen und zwar so rechtzeitig, dass die
            Kanzlei eine angemessene Bearbeitungszeit zur Verfügung steht. Entsprechendes gilt für die Unterrichtung über alle Vorgänge und Umstände, die für
            die Ausführung des Auftrags von Bedeutung sein können.
          </li>
          <li>
            Während der Dauer des Mandats kommuniziert der Mandant nur nach vorheriger Abstimmung mit der Kanzlei mit Gerichten, (Rechtsschutz-) Versicherungen,
            Behörden und der Gegenseite und deren rechtlichen Beratern.
          </li>
          <li>Terminabsagen teilt der Mandant der Kanzlei mindestens 24 Stunden vorher mit.</li>
        </ol>
        <Typography variant={"h3"}>{counter()} Verschwiegenheit und Datenschutz</Typography>
        <ol>
          <li>
            Die für die Kanzlei tätigen Rechtsanwälte und die übrigen Mitarbeiter (m/w) unterliegen im Rahmen der gesetzlichen Bestimmungen der
            Verschwiegenheit.
          </li>
          <li>
            Gegenüber den folgenden Dienstleistern entbindet der Mandant die Kanzlei von der Verschwiegenheitspflicht, sofern und soweit dies für eine
            sachgerechte und effiziente Bearbeitung und Abwicklung des Mandats in rechtlich-inhaltlicher sowie administrativer Hinsicht sinnvoll ist:
            (Rechtsschutz-) Versicherungen, in das Mandat eingebundene Rechtsanwälte, Steuerberater, Wirtschaftsprüfer, Sachverständige und sonstige Berater,
            externe EDV-Dienstleister, insbesondere den Plattformbetreiber, und Zertifizierungsstellen; die Berufshaftpflichtversicherung der Kanzlei sowie die
            Finanzbehörden und sonstige staatliche Stellen.
          </li>
          <li>
            Der Mandant ist damit einverstanden, dass die Kanzlei für die sachgerechte und effiziente Bearbeitung und Abwicklung des Mandats in
            rechtlich-inhaltlicher sowie administrativer Hinsicht notwendige personenbezogene Daten elektronisch speichert, nutzt und verarbeitet.
          </li>
          <li>
            Der Mandant ist ebenfalls damit einverstanden, dass die Kanzlei für die sachgerechte und effiziente Bearbeitung und Abwicklung des Mandats in
            rechtlich-inhaltlicher sowie administrativer Hinsicht notwendige personenbezogene Daten an den Plattformbetreiber weiterleitet, welcher die Daten
            elektronisch speichert, nutzt und verarbeitet. Der Plattformbetreiber stellt dem Mandanten einen eigenen Kundenbereich zur Verfügung, in dem der
            aktuelle Stand seines Mandates nachvollzogen werden kann und Kommunikation zum Mandat geführt werden kann.
          </li>
          <li>
            Der Mandant hat die <Link to={"/datenschutz"}>Datenschutzbedingungen</Link> der Kanzlei zur Kenntnis genommen und stimmt diesen zu.
          </li>
          <li>Der Mandant kann der Verarbeitung und Nutzung seiner personenbezogenen Daten zu Informationszwecken jederzeit widersprechen.</li>
        </ol>
        <Typography variant={"h3"} id="widerruf">
          {counter()} Widerrufsbelehrung
        </Typography>
        <ol>
          <li>
            Sofern der Mandant die Kanzlei als Verbraucher mit der Beratung und/oder Vertretung rechtlichen Angelegenheit unter ausschließlicher Verwendung von
            Fernkommunikationsmitteln (z.B. Telefon, E-Mail, Telefax, Webseite, Post) beauftragt, gilt für den Mandanten ein gesetzliches Widerrufsrecht.
            Verbraucher ist nach § 13 BGB nur, wer als natürliche Person ein Rechtsgeschäft zu Zwecken abschließen, die überwiegend weder der gewerblichen noch
            der selbständigen beruflichen Tätigkeit zugerechnet werden können.
          </li>
          <li>
            Ein Verbraucher hat das Recht, binnen vierzehn Tagen ohne Angabe von Gründen den Mandatsvertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn
            Tage ab dem Tag des Vertragsabschlusses.
          </li>
          <li>
            Um das Widerrufsrecht auszuüben, muss der Mandant der Kanzlei
            <p>
              {LAW_FIRM_NAME}
              <br />
              {LAW_FIRM_ADDRESS}
              <br />
              {LAW_FIRM_POSTAL_CODE} {LAW_FIRM_ADDRESS_LOCALITY}
              <br />
              Tel: {SERVICE_PHONE}
              <br />
              Fax: {LAW_FIRM_FAX}
              <br />
              Email: {LAW_FIRM_EMAIL}
            </p>
            <p>
              mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) über seinen Entschluss, diesen Vertrag zu
              widerrufen, informieren. Er kann dafür das beigefügte Muster-Widerrufsformular (s.u.) verwenden, das jedoch nicht vorgeschrieben ist. Zur Wahrung
              der Widerrufsfrist reicht es aus, dass er die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absendet.
            </p>
          </li>
        </ol>
        <Typography variant={"h3"}>{counter()} Folgen des Widerrufs</Typography>
        <ol>
          <li>
            Wenn der Mandant diesen Vertrag widerruft, hat die Kanzlei ihm alle Zahlungen, die diese von ihm erhalten hat, unverzüglich und spätestens binnen
            vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über den Widerruf des Vertrags bei der Kanzlei eingegangen ist. Für diese
            Rückzahlung verwendet die Kanzlei dasselbe Zahlungsmittel, das der Mandant bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit ihm
            wurde ausdrücklich etwas Anderes vereinbart. In keinem Fall werden dem Mandanten wegen dieser Rückzahlung Entgelte berechnet.
          </li>
          <li>
            Hat der Mandant verlangt, dass die Anwaltsdienstleistungen während der Widerrufsfrist beginnen sollen, so hat er der Kanzlei einen angemessenen
            Betrag zu zahlen, der dem Anteil der bis zu diesem Zeitpunkt, zu dem er die Kanzlei von der Ausübung des Widerrufsrechts hinsichtlich dieses
            Vertrages unterrichtet hat, bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen
            entspricht.
          </li>
          <li>
            <b>Wichtig:</b> In der Regel beginnen die Anwaltsdienstleistungen bereits nach Erteilen der Vollmacht, also demnach innerhalb der gesetzlichen
            Widerrufsfrist, u.a. durch die Prüfung des Falls des Mandanten und die Erstellung von Dokumenten durch den Anwalt. Sollten der Mandant nach Erteilen
            seiner Vollmacht - egal aus welchem Grund - die Anwaltsdienstleistungen beenden wollen, behält die Kanzlei einen Anspruch auf die gesetzlich
            vorgesehenen Gebühren nach § 15 Abs. 4 RVG. Der Höhe nach richtet sich die Vergütung ggf. nach besonderen Regelungen im Vergütungsverzeichnis, z.B.
            für Wertgebühren in Nrn. 3101, 3201, 3207, 3209, 3306 VV RVG.
          </li>
          <li>
            <b>Das Widerrufsrecht erlischt bei einem Vertrag über die Lieferung von nicht auf einem körperlichen Datenträger befindlichen digitalen Inhalte</b>{" "}
            (z.B. bei Dokumentendienstleistungen), wenn die Kanzlei mit der Ausführung des Vertrags begonnen hat, nachdem der Mandant dazu seine ausdrückliche
            Zustimmung gegeben hat und gleichzeitig seine Kenntnis davon bestätigt hat, dass sein Widerrufsrecht mit Beginn der Vertragsausführung erlischt.
          </li>
        </ol>
        <Typography variant={"h4"}>Muster-Widerrufsformular</Typography>
        <p>Wenn der Mandant den Vertrag widerrufen möchte, kann er dieses Formular ausfüllen und zurücksenden an:</p>
        <p>
          {LAW_FIRM_NAME}
          <br />
          {LAW_FIRM_ADDRESS}
          <br />
          {LAW_FIRM_POSTAL_CODE} {LAW_FIRM_ADDRESS_LOCALITY}
          <br />
          Tel: {SERVICE_PHONE}
          <br />
          Fax: {LAW_FIRM_FAX}
          <br />
          Email: {LAW_FIRM_EMAIL}
        </p>
        <p>Hiermit widerrufe(n) ich/wir den von mir/uns abgeschlossenen Vertrag über den Kauf der folgenden Dienstleistungen:</p>
        <ul>
          <li>Bestellt am:</li>
          <li>Name des/der Verbraucher(s):</li>
          <li>Anschrift des/der Verbraucher(s):</li>
          <li>Datum:</li>
        </ul>
        <p>
          <b>Ende der Widerrufsbelehrung</b>
        </p>
        <Typography variant={"h3"}>{counter()} Rechtswahl & Gerichtsstand</Typography>
        <ol>
          <li>Das Mandatsverhältnis unterliegt ausschließlich deutschem Recht.</li>
          <li>
            Gemäß § 38 Abs. 1 und 2 ZPO ist Köln Gerichtsstand für alle Streitigkeiten aus dem Mandatsverhältnis und aus allen mit dem Mandatsverhältnis in
            Zusammenhang stehenden Rechtsgründen; dies gilt allerdings nur dann, wenn der Mandant Kaufmann, juristische Person des öffentlichen Rechts oder ein
            öffentlich-rechtliches Sondervermögen ist oder keinen allgemeinen Gerichtsstand im Inland hat.
          </li>
        </ol>
      </Box>

      <p>Stand: 26. Mai 2023</p>
    </>
  );
};

export default TermsOfServiceLawFirm;
