/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { DOCUMENTS } from "../../../../services/globalVars";
import _get from "lodash/get";
import { getCaseLink } from "../../../../services/productService";
import { AbstractCase } from "../../../../types/Entities/AbstractCase";

type DocumentPaidProps = {
  product: AbstractCase;
  isFreeOrder?: boolean;
};

const DocumentPaid = ({ isFreeOrder = false, product }: DocumentPaidProps) => {
  const translate = (path: string) => {
    return _get(DOCUMENTS, path);
  };

  return (
    <>
      <p>
        Sie können sich nun {translate(product.name + ".titlePossessive")} herunterladen. Sie finden das Dokument jederzeit in Ihrer Servicewelt unter
        “Dokumente”.
        {!isFreeOrder && " Zudem erhalten Sie per E-Mail eine Zahlbestätigung und Ihre Rechnung."}
      </p>
      <Button fullWidth variant={"contained"} color="primary" component={Link} to={getCaseLink(product)}>
        Zu meinem Dokument
      </Button>
    </>
  );
};

export default DocumentPaid;
