import React, { useState } from "react";
import Layout from "../../../Layouts/Layout";
import TextContainer from "../../../../components/Container/TextContainer";
import Paper from "@mui/material/Paper";
import revokePageStyle from "./revokePageStyle";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import ButtonLoading from "../../../../components/Button/ButtonLoading";
import Container from "../../../../components/Container/Container";
import LegalbirdLoader from "../../../../components/ContentLoader/LegalbirdLoader";
import moment from "moment";
import Button from "@mui/material/Button";
import { Link, Navigate } from "react-router-dom";
import { getCaseLink, getProductData } from "../../../../services/productService";
import Typography from "@mui/material/Typography";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchResource, queryKeys, updateResource } from "../../../../services/reactQuery/reactQueryService";
import useProductParams from "../../../../hooks/useProductParams";

const RevokePage = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isLoadingInternal, setIsLoadingInternal] = useState(false);
  const { productId } = useProductParams();
  const productApiUri = getProductData("traffic", "apiUri");
  const { data: traffic, isLoading } = useQuery(queryKeys.item(productApiUri, productId), () => fetchResource(productApiUri, productId));
  const queryClient = useQueryClient();
  const updateMutation = useMutation(updateResource, {
    onSuccess: (data, variables) => queryClient.setQueryData(queryKeys.item(variables.uri, variables.id), data),
  });
  const confirmRevoke = async () => {
    setIsLoadingInternal(true);
    await updateMutation.mutateAsync({
      uri: "/traffic",
      id: traffic.id,
      data: {
        customerAppealRevocationApproved: moment().format("YYYY-MM-DD HH:mm:ss"),
      },
    });
    setIsLoadingInternal(false);
  };

  if (isLoading) {
    return (
      <Layout>
        <Container sx={{ width: 250 }}>
          <LegalbirdLoader />
        </Container>
      </Layout>
    );
  }

  if (traffic.investigation.assessment !== "revoke") {
    return <Navigate to={getCaseLink(traffic)} />;
  }

  const customerApprovedDate = moment(traffic.customerAppealRevocationApproved).format("DD.MM.YYYY");
  const customerApprovedTime = moment(traffic.customerAppealRevocationApproved).format("HH:mm");

  if (!!traffic.customerAppealRevocationApproved) {
    return (
      <Layout>
        <TextContainer>
          <Paper elevation={4} sx={revokePageStyle.container}>
            <Typography variant={"h4"} sx={revokePageStyle.headline}>
              Einspruchsrücknahme wurde bestätigt
            </Typography>
            <Grid container alignContent={"center"} alignItems={"center"} spacing={5}>
              <Grid item xs={12}>
                Wir haben Ihre Bestätigung der Rücknahme <br />
                am{" "}
                <strong>
                  {customerApprovedDate} {customerApprovedTime !== "00:00" && "um " + customerApprovedTime + " Uhr"}
                </strong>{" "}
                erhalten <br />
                und leiten nun alle weiteren Schritte für Sie ein. <br />
                <br />
                Sie müssen lediglich der Zahlung der verhängten Buße nachkommen.
              </Grid>
              <Grid item xs={12} md={3} />
              <Grid item xs={12} md={6}>
                <Button variant={"text"} color={"primary"} component={Link} to={"/servicewelt"} fullWidth>
                  Zu meinem Fall
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </TextContainer>
      </Layout>
    );
  }

  return (
    <Layout>
      <TextContainer>
        <Paper elevation={4} sx={revokePageStyle.container}>
          <Typography variant={"h4"} sx={revokePageStyle.headline}>
            Bestätigung der Einspruchsrücknahme
          </Typography>
          <Grid container alignContent={"center"} alignItems={"center"} spacing={5}>
            <Grid item xs={12}>
              Sobald der Einspruch zurückgenommen wurde, erhalten Sie hierzu eine entsprechende Bestätigung per E-Mail.
            </Grid>
            <Grid item xs={2}>
              <Checkbox checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
            </Grid>
            <Grid item xs={10} sx={revokePageStyle.checkboxText}>
              Ich wurde von den Legalbird Rechtsanwälten darüber aufgeklärt, dass die Abwehr der Buße schlechte Erfolgsaussichten hat. Mir ist bekannt, dass der
              Bußgeldbescheid somit rechtswirksam wird und die verhangene Buße innerhalb von 14 Tagen zu bezahlen ist.
            </Grid>
            <Grid item xs={12} md={3} />
            <Grid item xs={12} md={6}>
              <ButtonLoading
                fullWidth={true}
                variant={"contained"}
                disabled={!isChecked}
                isLoading={isLoadingInternal}
                color={"primary"}
                onClick={confirmRevoke}
              >
                Rücknahme bestätigen
              </ButtonLoading>
            </Grid>
          </Grid>
        </Paper>
      </TextContainer>
    </Layout>
  );
};

export default RevokePage;
