/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import { Helmet } from "react-helmet-async";

import { gaSet } from "../../../../../services/cookieService";

const LpAlimonyAdvoCardMeta = () => {
  const title = "Unterhalt berechnen | Legalbird";
  gaSet({ title: title });

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content="Berechnen Sie als ADVOCARD Kunde Ihren Unterhalt! - Schnell, einfach und sicher!" />
      <meta name="keywords" content="Unterhalt, Trennungsunterhalt, Kindesunterhalt" />
      <meta name="robots" content="noindex,nofollow" />
      <meta property="og:title" content={title} />
      <meta property="og:locale" content="de_DE" />
      <meta property="og:type" content="website" />
      <meta property="og:description" content="Berechnen Sie als ADVOCARD Kunde Ihren Unterhalt! - Schnell, einfach und sicher!" />
    </Helmet>
  );
};

export default LpAlimonyAdvoCardMeta;
