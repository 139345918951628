/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import termsOfServiceStyle from "../termsOfServiceStyle";
import { HashLink as Link } from "react-router-hash-link";
import { RS_ADDRESS, RS_ADDRESS_LOCALITY, RS_NAME, RS_POSTAL_CODE, SERVICE_EMAIL, SERVICE_PHONE } from "../../../services/globalVars";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

const TermsOfServiceLegalbirdFinancing = () => {
  let number = 0;
  const counter = () => {
    number++;
    return number;
  };

  return (
    <Box sx={termsOfServiceStyle.termsOfServicePage} id="agb">
      <Typography variant={"h1"} sx={termsOfServiceStyle.title}>
        Allgemeine Geschäftsbedingungen
      </Typography>
      <Typography variant={"h3"}>Prozessfinanzierung der Legalbird GmbH</Typography>
      <p>
        Die {RS_NAME}, {RS_ADDRESS}, {RS_POSTAL_CODE} {RS_ADDRESS_LOCALITY} (<b>"Legalbird"</b>) bietet privaten Verbrauchern (<b>"Endkunden"</b>) eine
        Finanzierung gerichtlicher und außergerichtlicher Verfahren und Auseinandersetzung auf dem Gebiet des Arbeitsrechts (Prozessfinanzierung). Durch unsere
        Beauftragung erkennen Sie diese AGB an.
      </p>
      <p>
        Legalbird (<b>"Auftragnehmer"</b>) übernimmt für Sie (<b>"Arbeitnehmer"</b>) die Prozessfinanzierung eines Rechtsanwaltes zum Zwecke der der
        außergerichtlichen und/oder gerichtlichen Verfolgung der arbeitsrechtlichen bereits entstandenen oder zukünftig entstehenden Ansprüche (
        <b>"Forderung(en)"</b>) des Arbeitnehmers gegenüber seinem Arbeitgeber soweit die weitere Verfolgung der Forderungen nach Beurteilung des Auftragnehmers
        erfolgversprechend erscheint.
      </p>
      <p>
        Die Durchsetzung erfolgt dabei außergerichtlich wie auch gerichtlich durch im Arbeitsrecht ordentlich qualifizierte Rechtsanwälte (
        <b>"Vertragsanwälte"</b>). Wenn der Arbeitnehmer den Vertragsanwalt beauftragt, kommt ein anwaltlicher Beratungsvertrag (Mandat) allein zwischen dem
        Arbeitnehmer und dem Vertragsanwalt auf Grundlage der jeweiligen Mandatsbedingungen zustande. Der Arbeitnehmer gestattet dem Auftragnehmer, dem
        Vertragsanwalt Zugang zu sämtlichen von dem Arbeitnehmer an den Auftragnehmer übermittelten Unterlagen zu gewähren beziehungsweise diese an den
        Vertragsanwalt zu übermitteln. Der Arbeitnehmer entbindet den Vertragsanwalt gegenüber dem Auftragnehmer ausdrücklich von der anwaltlichen Pflicht zur
        Verschwiegenheit (§ 43a Abs. 2 BRAO). Der Auftragnehmer benötigt die Informationen, um eine schnelle und effiziente Abwicklung zu gewährleisten und die
        Prozessfinanzierung für den Arbeitnehmer so einfach wie möglich zu gestalten.
      </p>
      <p>
        <b>
          Legalbird weist ausdrücklich darauf hin, selbst weder Betreiber einer Anwaltskanzlei zu sein noch anwaltliche oder sonstige rechtliche Beratung in
          eigenem Namen durchzuführen.
        </b>
      </p>

      <Typography variant={"h3"}>{counter()} Zustandekommen des Vertrages und Kostenübernahme</Typography>
      <p>
        Durch Anklicken des Buttons "Vollmacht erteilen und kostenpflichtig beauftragen" im Rahmen des Mandatierungsprozesses geben Sie ein verbindliches
        Angebot zum Abschluss eines entgeltlichen Prozessfinanzierungsvertrages (<b>"Prozessfinanzierung"</b>) zur Übernahme der Risiken und Kosten ab. Wir
        nehmen Ihren Auftrag durch ausdrückliche Erklärung (z.B. per E-Mail) an.
      </p>
      <p>
        Legalbird übernimmt dann die gesetzlichen Kosten der Anspruchsdurchsetzung, d.h. die Kosten der anwaltlichen außergerichtlichen und gerichtlichen
        Vertretung, die Gerichtskosten sowie bei (Teil-) Unterliegen die Kosten des Gegners (<b>"Kostenübernahme"</b>). Die Kostenübernahme gilt nur bei
        Vertretung durch einen Legalbird-Partneranwalt und wenn dieser durch den Kunden angewiesen wurde, Legalbird jederzeit und vollständig über alle
        Einzelheiten des Mandats unterrichtet zu halten. Soweit der Kunde seine Rechtsschutzversicherung in Anspruch nehmen möchte, gilt die Kostenübernahme
        nicht.
      </p>
      <p>
        Die Kostenübernahme erlischt, soweit Legalbird für die Anspruchsbeurteilung wesentliche Einzelheiten nicht mitgeteilt werden oder der Kunde einer
        Erlösauskehr an den Partneranwalt in Höhe des Vergütungsanspruchs an Legalbird nicht zustimmt.
      </p>
      <p>
        Die durch Legalbird oder den Partneranwalt erfragten Tatsachen und Umstände sind vollständig und wahrheitsgemäß vom Kunden anzugeben; sie sind umgehend
        und unaufgefordert bei einer Veränderung zu korrigieren. Der Kunde wird Legalbird alle sonstigen Informationen unaufgefordert mitteilen, die nach
        verständiger Würdigung eines gewissenhaft handelnden Auftraggebers für die Durchsetzung der Ansprüche von Bedeutung sein könnten. Eine Zuwiderhandlung
        gegen die in dieser Ziffer genannten Pfichten seitens des Kunden führt ebenso zu einem Wegfall der Kostenübernahme.
      </p>
      <p>
        Mit der Beauftragung von Legalbird bestätigt der Kunde, dass seine Ansprüche unbelastet und nicht bereits an einen Dritten abgetreten sind oder ein
        Dritter mit der Durchsetzung beauftragt ist; der Kunde wird keinen Dritten mit der Durchsetzung der Ansprüche beauftragen, soweit die Kostenübernahme
        durch Legalbird erteilt ist.
      </p>
      <p>Der Auftragnehmer zahlt die nach Mandatierung des Vertragsanwalts entstehenden und zur Verfolgung der streitigen Forderungen notwendigen Kosten.</p>

      <Typography variant={"h3"}>{counter()} Vergütung von Legalbird in Abfindungsfällen</Typography>
      <ol>
        <li>
          Legalbird definiert zu Beginn eines Abfindungs- oder Kündigungsfalles eine Vergütung (<b>"Erfolgsprovision"</b>), die vom Kunden an Legalbird zu
          zahlen ist, sollte das Verfahren für den Kunden erfolgreich ausgehen. Als "erfolgreich" im vorgenannten Sinne, werden nachfolgend Fälle bezeichnet, in
          denen
          <ol type={"a"}>
            <li>der Arbeitgeber außergerichtlich oder im Rahmen eines Kündigungsschutzprozesses eine Abfindung anbietet oder leistet</li>
            <li>der Arbeitgeber anbietet, eine fristlose Kündigung des Kunden in eine ordentliche Kündigung umzuwandeln, oder dies tatsächlich umsetzt oder</li>
            <li>die Weiterbeschäftigung des Kunden erreicht wird</li>
          </ol>
        </li>
        <li>
          Die Höhe der an Legalbird zu zahlenden Erfolgsprovision beträgt in Abhängigkeit vom Aushang des Verfahrens
          <ol type={"a"}>
            <li>
              im Falle von {number}.1 a) ein Drittel (inkl. MwSt.) des vom Arbeitgeber gebotenen oder geleisteten (Brutto-)Abfindungsbetrages inkl. ggf.
              erlassener Verbindlichkeiten für den Kunden
            </li>
            <li>
              im Falle von {number}.1 b) ein Drittel (inkl. MwSt.) des (Brutto-)Gehaltes, das über die Zeitspanne der ordentlichen Kündigungsfrist gezahlt
              worden wäre oder
            </li>
            <li>im Falle von {number}.2 c) ein volles (Brutto-)Monatsgehalt des Kunden</li>
          </ol>
          Die Erfolgsprovision beträgt aber in allen Fällen mindestens aber 600 € pro Mandat.
          <br />
          Die Erfolgsprovision bleibt unberührt davon, ob Kosten des Verfahrens dem Gegner auferlegt wurden oder der Gegner diese im Vergleichswege übernommen
          hat.
        </li>
        <li>
          Die Erfolgsprovision wird im Falle von {number}.1 a) mit Empfang der entsprechenden Leistungen durch den Partneranwalt oder durch den Kunden fällig.
          Im Falle von {number}.1 b) oder c) wird die Erfolgsprovision mit der protokollierten oder vertraglich vereinbarten Einigung fällig. Der
          Vergütungsanspruch von Legalbird besteht auch, sollte ein Schuldner direkt an den Kunden leisten.
        </li>
        <li>
          <b>Sollten die Durchsetzungsbemühungen des Partneranwaltes nicht erfolgreich im Sinne von {number}.1 sein, entstehen für den Kunden keine Kosten.</b>
        </li>
        <li>
          Der Kunde wird den Partneranwalt sowie den Schuldner anweisen, etwaige Zahlungen ausschließlich an den Partneranwalt zu leisten; der Partneranwalt
          wird vom Kunden angewiesen, von den etwaig empfangenen Geldern den Provisionsanspruch von Legalbird direkt zu erfüllen.
        </li>
      </ol>

      <Typography variant={"h3"}>{counter()} Pflichten des Arbeitnehmers</Typography>
      <p>
        Soweit erforderlich, unterstützen Sie den Vertragsanwalt bei seinen Durchsetzungsbemühungen. Sie teilen uns den Sachverhalt nach Ihrem Kenntnisstand
        vollständig mit. Sie stellen die zur Bearbeitung erforderlichen Unterlagen/Daten fristgerecht zur Verfügung (insbesondere Arbeitsvertrag, Kündigung
        sowie Gehaltsabrechnungen) und reichen neue Informationen unverzüglich schriftlich an Ihren Vertragsanwalt nach. Für die Vollständigkeit und Richtigkeit
        der Unterlagen/Daten tragen Sie die Verantwortung. Sie sind verpflichtet, uns gegebenenfalls erforderliche ergänzende Informationen auf Anforderung
        unverzüglich zur Verfügung zu stellen.
      </p>
      <p>
        Während der Vertragsdauer mit uns dürfen Sie keinen anderen Rechtsanwalt oder sonstige Stellen mit der Durchsetzung der im Bestellprozess näher
        bestimmten Forderungen beauftragen und/oder gerichtliche Schritte eigenständig einleiten oder über die Forderungen verfügen.
      </p>
      <p>
        Der Arbeitnehmer entbindet den jeweils tätigen Vertragsanwalt unwiderruflich von seiner gesetzlich bestehenden Verschwiegenheitspflicht gegenüber dem
        Auftragnehmer.
      </p>

      <Typography variant={"h3"}>{counter()} Gerichtliche und außergerichtliche Vergleiche</Typography>
      <p>
        Der Abschluss eines Vergleichs bedarf bei einer Kostenübernahme der Zustimmung von Legalbird. Legalbird wird die Zustimmung unverzüglich erteilen,
        soweit ein wirtschaftlich vernünftig handelnder Anspruchsinhaber den Vergleich akzeptieren würde.
      </p>
      <p>
        Nach den Mandatsbedingungen ist der Vertragsanwalt im gerichtlichen Verfahren zum Abschluss eines widerrufbaren Vergleichs mit einer Widerrufsfrist von
        zwei Wochen in Bezug auf die Forderungen berechtigt, wenn die Vergleichssumme nach gewissenhafter Beurteilung eines sorgfältig handelnden Kaufmanns als
        angemessen erscheint. Wir werden Sie unverzüglich über den Abschluss eines Vergleichs benachrichtigen.
      </p>
      <p>Zu einer sonstigen Verfügung über die Forderung wie auch einem Verzicht sind wir nur mit Ihrer Zustimmung berechtigt.</p>
      <p>
        Sofern der im außergerichtlichen oder gerichtlichen Verfahren erzielte Abfindungsvorschlag der Abfindungshöhe nach § 1a Abs. 2 S. 1
        Kündigungsschutzgesetz entspricht und der Arbeitnehmer diesen entgegen einer rechtlichen Empfehlung des Vertragsanwaltes ablehnt, behält der
        Auftragsnehmer seinen Vergütungsanspruch nach Ziffer 2.
      </p>

      <Typography variant={"h3"}>{counter()} Vertragsbeendigung</Typography>

      <p>
        Der Auftragnehmer übernimmt das Prozesskostenrisiko so, wie es sich bei Vertragsschluss dargestellt hat. Sollten Umstände neu eintreten oder dem
        Auftragnehmer erstmalig bekannt werden, aufgrund derer die Erfolgsaussichten des Prozesses erheblich schlechter zu bewerten sind als bei
        Vertragsschluss, ist der Auftragnehmer berechtigt, diesen Vertrag mit sofortiger Wirkung zu kündigen und die weitere Finanzierung des Prozesses
        einzustellen. Entsprechend ist der Auftragnehmer auch zu einer Teilkündigung des Vertrages berechtigt mit der Folge, die Prozessfinanzierung nur
        hinsichtlich eines Teiles der Forderungen fortzuführen. In diesem Sinne kommt eine Kündigung des Vertrages seitens des Auftragnehmers insbesondere in
        Betracht bei
      </p>
      <ul>
        <li>Gerichts- oder Behördenentscheidungen, mit denen die streitigen Forderungen ganz oder zum Teil abgelehnt werden;</li>
        <li>neue obergerichtliche Rechtsprechung, die negativen Einfluss auf den Ausgang des Prozesses haben kann;</li>
        <li>Verschlechterung oder Wegfall von Beweismöglichkeiten;</li>
        <li>Vermögensverfall des Anspruchsgegners;</li>
        <li>gerichtliche Hinweise gemäß § 139 ZPO mit nachteiligem Inhalt für die Erfolgsaussichten.</li>
      </ul>
      <p>
        In jedem Falle ist der Auftragnehmer berechtigt, den Vertrag nach Abschluss jeder Instanz ganz oder teilweise zu kündigen und die weitere
        Prozessfinanzierung einzustellen.
      </p>
      <p>
        Im Falle der Kündigung des Vertrages durch den Auftragnehmer zahlt der Auftragnehmer die bis dahin entstandenen Kosten, wie sie bei einer sofortigen,
        möglichst kostengünstigen (teilweisen) Beendigung des Verfahrens anfielen. Dem Arbeitnehmer steht es frei, das Verfahren auf eigene Kosten
        weiterzuverfolgen und die streitigen Forderungen weiter auf eigene Kosten durchzusetzen. Realisiert der Arbeitnehmer dann Erlöse, ist er lediglich
        verpflichtet, dem Auftragnehmer die aufgewendeten Auslagen zu erstatten. Die Erlösbeteiligung entfällt.
      </p>
      <p>Der Arbeitnehmer kann diesen Vertrag nur aus wichtigem Grund kündigen.</p>

      <Typography variant={"h3"}>{counter()} Haftung</Typography>

      <p>
        Im Hinblick auf die in diesen AGB beschriebenen entgeltlichen und unentgeltlichen Leistungen haftet Legalbird, gleich aus welchem Rechtsgrund, nur für
        Vorsatz, grobe Fahrlässigkeit sowie die fahrlässige Verletzung wesentlicher Vertragspflichten oder Pflichten, deren Erfüllung die Vertragsdurchführung
        überhaupt erst ermöglicht und auf deren Einhaltung der Kunde regelmäßig vertrauen darf (sog. Kardinalpflichten).
      </p>
      <p>Bei einfacher Fahrlässigkeit ist die Haftung auf den Ersatz des vorhersehbaren, vertragstypischen Schadens beschränkt.</p>
      <p>
        Die Haftungsbeschränkungen gemäß der vorgenannen Absätze dieser Ziffer gelten nicht für Schäden aus der Verletzung des Lebens, des Körpers oder der
        Gesundheit, oder einer von Legalbird gegebenen Garantie sowie in Fällen von Arglist und bei Schäden nach dem Produkthaftungsgesetz.
      </p>
      <p>
        Für den Verlust von Daten haftet Legalbird nur, wenn dieser Verlust durch angemessene Datensicherungsmaßnahmen auf Seiten des Kunden nicht vermeidbar
        gewesen wäre.
      </p>
      <p>
        Legalbird betreibt die Plattform im Rahmen der technischen Möglichkeiten. Es besteht kein Anspruch auf jederzeitige fehler- und unterbrechungsfreie
        Nutzung der Plattform oder eine bestimmte Verfügbarkeit.
      </p>
      <p>
        Soweit die Haftung nach diesen AGB ausgeschlossen oder beschränkt ist, gilt dies auch für die persönliche Haftung der gesetzlichen Vertreter,
        Mitarbeiter und Erfüllungsgehilfen.
      </p>

      <Typography variant={"h3"}>{counter()} Widerrufsbelehrung</Typography>
      <p>
        Die folgende Widerrufsbelehrung bezieht sich auf den Widerruf von Legalbird-Leistungen gemäß dieser AGB, nicht auf Leistungen unserer Partnerkanzlei,
        für die eine gesonderte <Link to={"/agb#widerruf"}>Widerrufsbelehrung der Kanzlei</Link> aufgeführt ist.
      </p>
      <Typography variant={"h4"}>{number}.1 Widerruf</Typography>
      <p>
        Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des
        Vertragsabschlusses. Um Ihr Widerrufsrecht auszuüben, müssen Sie der
      </p>
      <p>
        {RS_NAME}
        <br />
        {RS_ADDRESS}
        <br />
        {RS_POSTAL_CODE} {RS_ADDRESS_LOCALITY}
        <br />
        Tel: {SERVICE_PHONE}
        <br />
        Email: {SERVICE_EMAIL}
      </p>
      <p>
        mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen,
        informieren. Sie können dafür das beigefügte Muster-Widerrufsformular (s.u.) verwenden, das jedoch nicht vorgeschrieben ist. Zur Wahrung der
        Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
      </p>
      <Typography variant={"h4"}>{number}.2 Folgen des Widerrufs</Typography>
      <p>
        Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, unverzüglich und spätestens binnen vierzehn Tagen
        ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf des Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe
        Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas Anderes vereinbart. In
        keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.
      </p>
      <p>
        Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen sollen, so haben Sie uns einen angemessenen Betrag zu zahlen, der dem
        Anteil der bis zu diesem Zeitpunkt, zu dem Sie uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrages unterrichtet haben, bereits
        erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.
      </p>

      <Typography variant={"h4"}>{number}.3 Erlöschen des Widerrufsrechts</Typography>
      <p>
        <b>Das Widerrufsrecht erlischt bei einem Vertrag zur Erbringung von Dienstleistungen</b> gemäß dieser AGB, wenn Legalbird die Dienstleistung vollständig
        erbracht hat, mit der Ausführung der Dienstleistung erst begonnen hat, nachdem der Kunde dazu die ausdrückliche Zustimmung gegeben hat und gleichzeitig
        bestätigt hat, dass er sich bewusst ist, dass sein Widerrufsrecht mit Beginn der Vertragsausführung erlischt.
      </p>

      <Typography variant={"h4"}>{number}.4 Muster-Widerrufsformular</Typography>

      <p>Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück an:</p>
      <p>
        {RS_NAME}
        <br />
        {RS_ADDRESS}
        <br />
        {RS_POSTAL_CODE} {RS_ADDRESS_LOCALITY}
        <br />
        Tel: {SERVICE_PHONE}
        <br />
        Email: {SERVICE_EMAIL}
      </p>
      <p>Hiermit widerrufe(n) ich/wir den von mir/uns abgeschlossenen Vertrag über den Kauf der folgenden Dienstleistungen:</p>
      <ul>
        <li>Bestellt am:</li>
        <li>Name des/der Verbraucher(s):</li>
        <li>Anschrift des/der Verbraucher(s):</li>
        <li>Datum:</li>
      </ul>

      <p>
        <b>Ende der Widerrufsbelehrung</b>
      </p>

      <Typography variant={"h3"}>{counter()} Schlussbestimmungen</Typography>
      <ol>
        <li>
          Diese Allgemeinen Geschäftsbedingungen sind vollständig und abschließend. Änderungen und Ergänzungen dieser Geschäftsbedingungen sollten, um
          Unklarheiten oder Streit zwischen den Parteien über den jeweils vereinbarten Vertragsinhalt zu vermeiden, schriftlich gefasst werden.
        </li>
        <li>
          Falls sich eine der vorstehenden Bestimmungen als unwirksam oder nicht durchsetzbar erweisen sollte, bleiben die übrigen Bestimmungen weiterhin
          gültig.
        </li>
        <li>Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss der Verweisung auf andere Rechtsordnungen.</li>
        <li>
          Mit der Verordnung (EU) Nr. 524/2013 hat die Europäische Kommission eine Plattform für die außergerichtliche Beilegung von Streitigkeiten geschaffen,
          die unter https://ec.europa.eu/consumers/odr/ aufrufbar ist. Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle nicht bereit oder verpflichtet (Information gemäß § 36 des deutschen Gesetzes über die alternative Streitbeilegung in
          Verbrauchersachen (Verbraucherstreitbeilegungsgesetzes - VSBG)).
        </li>
      </ol>
      <p>Stand: 18. Mai 2020</p>
    </Box>
  );
};

export default TermsOfServiceLegalbirdFinancing;
