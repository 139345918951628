/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import { Helmet } from "react-helmet-async";

import { gaSet } from "../../../../../services/cookieService";

const LpDismissalCheck24Meta = () => {
  const title = "Abfindung bei Kündigung| Legalbird";
  gaSet({ title: title });

  return (
    <Helmet>
      <title>{title}</title>
      <meta
        name="description"
        content="Reichen Sie als CHECK24 Kunde Ihre Kündigungsschutzklage ein - schnell, sicher & einfach!
      Kündigungsschutzklage auch ohne Anwaltsbesuch möglich. Höhe Ihrer Abfindung berechnen. Professionell vom Fachanwalt beraten lassen!"
      />
      <meta
        name="keywords"
        content="CHECK24, Abfindung, Kündigung, Kündigungsschutzklage, Abfindung sichern, Abfindung erhalten, Anspruch auf Abfindung prüfen"
      />
      <meta name="robots" content="noindex,nofollow" />
      <meta property="og:title" content={title} />
      <meta property="og:locale" content="de_DE" />
      <meta property="og:type" content="website" />
      <meta
        property="og:description"
        content="Reichen Sie als CHECK24 Kunde Ihre Kündigungsschutzklage ein - schnell, sicher & einfach!
      Kündigungsschutzklage auch ohne Anwaltsbesuch möglich. Höhe Ihrer Abfindung berechnen. Professionell vom Fachanwalt beraten lassen!"
      />
    </Helmet>
  );
};

export default LpDismissalCheck24Meta;
