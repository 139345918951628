import ApiClient from "../apiClient";
import { MediaObject } from "../../types/Entities/MediaObject/MediaObject";

export interface CreateMediaObjectParams {
  uri?: string;
  data: Required<Pick<MediaObject, "file" | "fieldname" | "product" | "description" | "productId">> & {
    electronicFileFolderPath?: MediaObject["electronicFileFolderPath"];
  };
  options?: Record<string, string>;
}

export const createMediaObject = async ({ uri = "/media_objects", data, options }: CreateMediaObjectParams): Promise<any> => {
  let formData = new FormData();
  formData.append("file", data.file);
  formData.append("fieldname", data.fieldname);
  formData.append("product", data.product);
  formData.append("description", data.description);
  formData.append("productId", String(data.productId));

  if (!!data.electronicFileFolderPath) {
    formData.append("electronicFileFolderPath", data.electronicFileFolderPath);
  }

  let headers = new Headers();
  headers.set("Content-Type", "multipart/form-data");
  return await ApiClient.post(uri, {
    headers: headers,
    body: formData,
    ...(options || {}),
  });
};
