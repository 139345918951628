import React, {ReactNode} from "react";
import serviceWorldContentBoxStyle from "./serviceWorldContentBoxStyle";
import { Grid, Paper, Theme } from "@mui/material";
import Typography from "@mui/material/Typography";
import IconWrapper, { IconWrapperProps } from "../IconWrapper/IconWrapper";

type ServiceWorldContentBoxProps = {
  icon?: IconWrapperProps["icon"] | null;
  altText?: string;
  headline: string;
  children?: ReactNode;
};

const ServiceWorldContentBox = ({ icon = null, altText = "Icon", headline, children }: ServiceWorldContentBoxProps) => {
  return (
    <Paper elevation={4} sx={serviceWorldContentBoxStyle.contentBoxContainer}>
      <Grid container justifyContent={"center"} wrap={"nowrap"} sx={serviceWorldContentBoxStyle.contentBoxHeadline}>
        <Grid item>
          <Grid container alignItems={"center"}>
            {icon && (
              <Grid item sx={serviceWorldContentBoxStyle.imageCircleContainer}>
                <IconWrapper icon={icon} alt={altText} sx={serviceWorldContentBoxStyle.headerIcon} />
              </Grid>
            )}
            <Grid item>
              <Typography variant={"h5"} component={"h3"} align={"center"} paddingX={"2rem"}>
                {headline}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {children}
    </Paper>
  );
};

export default ServiceWorldContentBox;
