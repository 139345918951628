/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

// javascript part of theme removed to own file to prevent ts-ignore
// TODO: refactor theme to typescript and new mui standards
import legacyTheme from "./LegacyTheme";

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    buttonGrey: true;
    buttonText: true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    mode: "light" | "dark";
    iconColor: {
      main: string;
    };
  }
}

export default legacyTheme;
