/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React, { useState } from "react";
import navigationMenuMobileStyle from "./navigationMenuMobileStyle";
import { Box, Collapse, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Switch } from "@mui/material";
import { ExpandLess, ExpandMore, Menu as MenuIcon } from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DialogModal from "../../../DialogModal/DialogModal";
import * as messagesActions from "../../../../store/messages/actions";
import { logout } from "../../../../store/login/actions";
import Logo from "../../Logo/Logo";
import { navigationLinks } from "../../../../services/layoutService";
import _map from "lodash/map";
import LoginAction from "../../../Login/LoginAction";
import { modalview, pageview } from "../../../../services/useTracker";
import { useCustomer } from "../../../../provider/Customer/CustomerProvider";
import { useMutation } from "@tanstack/react-query";
import { updateResource } from "../../../../services/reactQuery/reactQueryService";
import { handleThemeSwitch } from "../../../../services/serviceWorldService";

type NavigationMenuMobileProps = {
  clickableLogo: boolean;
  hideMainNavigation: boolean;
};
export default function NavigationMenuMobile({ clickableLogo, hideMainNavigation = false }: NavigationMenuMobileProps) {
  const { customer, isLoggedIn, refreshCustomer } = useCustomer();
  const navigate = useNavigate();
  const location = useLocation();
  const [loginLayerTitle, setLoginLayerTitle] = useState("Anmelden");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const updateMutation = useMutation(updateResource);

  const handleCloseLoginLayer = () => {
    navigate(-1);
    pageview(location.pathname);
  };

  const handleOpenLoginLayer = () => {
    setDrawerOpen(false);
    navigate(location.pathname, { state: { loginModalOpen: "loginIcon" } });
    modalview("Login Layer Modal");
  };

  const toggleSubMenu = (subListName: string) => {
    if (subMenuOpen === subListName) {
      setSubMenuOpen("");
      return;
    }
    setSubMenuOpen(subListName);
  };

  const closeMenu = () => {
    setSubMenuOpen("");
    setDrawerOpen(false);
  };

  const triggerVerifyPrompt = () => {
    messagesActions.addMessage({
      type: "info",
      text: "Bitte bestätigen Sie Ihre E-Mail-Adresse, damit Sie alle Funktionen der Servicewelt nutzen können.",
    });
  };

  const handleLogout = () => {
    logout();
    closeMenu();
    navigate("/");
  };

  const LinkOrVerifyPromptButton = ({ label, link, verification }: { label: string; link: string; verification: string | null }) => {
    if (verification) {
      return (
        <ListItemButton component={Link} to={link}>
          <ListItemText>{label}</ListItemText>
        </ListItemButton>
      );
    }

    return (
      <ListItemButton onClick={() => triggerVerifyPrompt()}>
        <ListItemText>{label}</ListItemText>
      </ListItemButton>
    );
  };

  return (
    <>
      <IconButton onClick={() => setDrawerOpen(true)} size="large">
        <MenuIcon sx={{ color: "text.primary" }} />
      </IconButton>

      <Drawer anchor={"top"} open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <Box sx={navigationMenuMobileStyle.logoContainer}>
          <Logo target={isLoggedIn ? "/servicewelt" : "/"} isClickable={clickableLogo} />
        </Box>
        {!hideMainNavigation && (
          <List>
            <ListItemButton onClick={() => toggleSubMenu("areas")}>
              <ListItemText>Rechtsbereiche</ListItemText>
              <ListItemIcon>{subMenuOpen === "areas" ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
            </ListItemButton>
            <Collapse in={subMenuOpen === "areas"}>
              <List sx={navigationMenuMobileStyle.subMenu}>
                {_map(navigationLinks.areas, (link, index) => (
                  <ListItemButton key={index} component={Link} to={link.to} onClick={() => setDrawerOpen(false)}>
                    <ListItemText>{link.label}</ListItemText>
                  </ListItemButton>
                ))}
              </List>
            </Collapse>

            <ListItemButton onClick={() => toggleSubMenu("guidebooks")}>
              <ListItemText>Ratgeber</ListItemText>
              <ListItemIcon>{subMenuOpen === "guidebooks" ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
            </ListItemButton>
            <Collapse in={subMenuOpen === "guidebooks"}>
              <List sx={navigationMenuMobileStyle.subMenu}>
                {_map(navigationLinks.guidebooks, (link, index) => (
                  <ListItemButton key={index} component={Link} to={link.to} onClick={() => setDrawerOpen(false)}>
                    <ListItemText>{link.label}</ListItemText>
                  </ListItemButton>
                ))}
              </List>
            </Collapse>

            <ListItemButton onClick={() => toggleSubMenu("about-us")}>
              <ListItemText>Über Legalbird</ListItemText>
              <ListItemIcon>{subMenuOpen === "about-us" ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
            </ListItemButton>
            <Collapse in={subMenuOpen === "about-us"}>
              <List sx={navigationMenuMobileStyle.subMenu}>
                {_map(navigationLinks.aboutUs, (link, index) => (
                  <ListItemButton key={index} component={Link} to={link.to} onClick={() => setDrawerOpen(false)}>
                    <ListItemText>{link.label}</ListItemText>
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </List>
        )}
        <Divider />
        <List>
          {isLoggedIn && customer ? (
            <>
              <LinkOrVerifyPromptButton verification={customer.verification} link={"/servicewelt"} label={"Meine Servicewelt"} />
              <LinkOrVerifyPromptButton verification={customer.verification} link={"/servicewelt/mein-konto"} label={"Mein Konto"} />
              <ListItem>
                <ListItemText>Dark Mode</ListItemText>
                <ListItemIcon>
                  <Switch
                    onChange={(event) => handleThemeSwitch(event, updateMutation, refreshCustomer, customer, setIsLoading)}
                    checked={customer?.userConfig?.theme === "dark"}
                    disabled={isLoading}
                  />
                </ListItemIcon>
              </ListItem>
              <ListItemButton onClick={handleLogout}>
                <ListItemText>Abmelden</ListItemText>
              </ListItemButton>
            </>
          ) : (
            <ListItemButton onClick={handleOpenLoginLayer}>
              <ListItemText>Anmelden</ListItemText>
            </ListItemButton>
          )}
        </List>
      </Drawer>
      <DialogModal open={!!(location.state && location.state.loginModalOpen === "loginIcon")} onClose={handleCloseLoginLayer} title={loginLayerTitle}>
        <LoginAction setLoginLayerTitle={setLoginLayerTitle} />
      </DialogModal>
    </>
  );
}
