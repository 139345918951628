/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import HintBox from "../Box/HintBox/HintBox";
import { AbstractCase } from "../../types/Entities/AbstractCase";

type UploadIntroTextProps = {
  divorce: AbstractCase;
};

const UploadIntroText = ({ divorce }: UploadIntroTextProps) => {
  const getIntroText = () => {
    switch (divorce.numberChildren) {
      case 0:
        return "Damit der Antrag bei Gericht eingereicht werden kann, benötigen wir Ihre Heiratsurkunde. ";
      case 1:
        return "Damit der Antrag bei Gericht eingereicht werden kann, benötigen wir Ihre Heiratsurkunde und die Geburtsurkunde Ihres Kindes. ";
      default:
        return "Damit der Antrag bei Gericht eingereicht werden kann, benötigen wir Ihre Heiratsurkunde und die Geburtsurkunden Ihrer Kinder. ";
    }
  };

  return (
    <HintBox headline={"Dokumente hochladen"}>
      <p>{divorce && getIntroText()}</p>
    </HintBox>
  );
};

export default UploadIntroText;
