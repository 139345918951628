import React from "react";
import processBoxStyle from "./processBoxStyle";
import { Grid, Typography } from "@mui/material";
import _map from "lodash/map";
import { getProcessConfig } from "../../../services/serviceWorldService";
import IconCheckCircleBig from "../../../assets/icons/serviceOverview/IconCheckCircleBig";
import IconProgress from "../../../assets/icons/serviceOverview/IconProgress";
import ContentBox from "../../Box/ContentBox/ContentBox";
import IconWrapper from "../../IconWrapper/IconWrapper";

// todo replace productClassName with correct type after productService refactor
type InitialProcessBoxProps = {
  productClassName: string;
};

//pretty redundant right now, but in the future the content might differ quite a bit
const InitialProcessBox = ({ productClassName }: InitialProcessBoxProps) => {
  const processConfig = getProcessConfig({ name: productClassName, leadStatus: 10 });

  if (!processConfig) {
    return null;
  }
  return (
    <ContentBox icon={IconProgress} headline={processConfig.headline} alignContent={"stretch"} loading={false}>
      <Grid container>
        {_map(processConfig.steps, (step, index: number) => (
          <React.Fragment key={index}>
            <Grid item xs={2}>
              <Grid container sx={processBoxStyle.iconContainer} direction="column" alignItems="center">
                {index === 0 ? <Grid item sx={processBoxStyle.connectorLineInvisible} /> : <Grid item sx={processBoxStyle.connectorLine} />}
                <Grid item sx={processBoxStyle.progressIcons}>
                  {step.done ? (
                    <IconCheckCircleBig sx={processBoxStyle.progressIcons} />
                  ) : (
                    <IconWrapper icon={step.icon} alt="Step Icon" sx={processBoxStyle.progressIcons} />
                  )}
                </Grid>
                {index === processConfig.steps.length - 1 ? (
                  <Grid item sx={processBoxStyle.connectorLineInvisible} />
                ) : (
                  <Grid item sx={processBoxStyle.connectorLine} />
                )}
              </Grid>
            </Grid>
            <Grid item xs={10} sx={[processBoxStyle.textBlock, step.done ? processBoxStyle.done : null]}>
              <Grid component={"span"} sx={processBoxStyle.stepHeading}>
                {step.headline}
              </Grid>
              <Typography sx={processBoxStyle.stepText}>{step.content}</Typography>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </ContentBox>
  );
};

export default InitialProcessBox;
