/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import Layout from "../../../../Layouts/Layout";
import DismissalCheckWbsPageMeta from "./DismissalCheckWbsPageMeta";
import LandingPageTeaser from "../../../../../components/Teaser/LandingPageTeaser";
import TrustSection from "../../../../../components/Sections/Generic/TrustSection/TrustSection";
import HowItWorksSection from "../../../../../components/Sections/Settlement/HowItWorksSection/HowItWorksSection";
import Container from "../../../../../components/Container/Container";
import DismissalCheckin from "../../../../../components/Checkin/DismissalCheckin/DismissalCheckin";
import SettlementTimeSection from "../../../../../components/Sections/Settlement/SettlementTimeSection/SettlementTimeSection";
import TeamSection from "../../../../../components/Sections/Generic/TeamSection/TeamSection";
import PartnerLogoSection from "../../../../../components/Sections/Generic/PartnerLogoSection/PartnerLogoSection";
import GreyBackgroundWrapper from "../../../../../components/BackgroundWrapper/GreyBackgroundWrapper";
import { settlementTeaserCards } from "../../../../../services/landingPageService";
import LinkedCardBox from "../../../../../components/LinkedCardBox/LinkedCardBox";
import { useCustomer } from "../../../../../provider/Customer/CustomerProvider";
import NoWinNoFeeSection from "../../../../../components/Sections/Settlement/NoWinNoFeeSection/NoWinNoFeeSection";
import PartnersSection from "../../../../../components/Sections/Generic/PartnersSection/PartnersSection";

const DismissalCheckWbsPage = () => {
  const { isLoggedIn } = useCustomer();

  return (
    <Layout backgroundType={"landingpage"}>
      <DismissalCheckWbsPageMeta />
      <LandingPageTeaser
        headline={"Kündigung? Jetzt prüfen lassen!"}
        subheadline={"Wir begleiten Sie professionell bei Ihrer Kündigung"}
        hasTeaserButton={true}
        teaserButtonText={"Kostenlos prüfen lassen"}
        teaserButtonLink={isLoggedIn ? "/servicewelt" : "#top-checkin"}
      >
        <PartnerLogoSection partnerName={"wbs"} containerStyle={"settlementContainer"} />
        <LinkedCardBox linkedCards={settlementTeaserCards} />
      </LandingPageTeaser>
      {!isLoggedIn && (
        <Container>
          <DismissalCheckin idPrefix={"top"} partner={"wbs"} />
        </Container>
      )}
      <GreyBackgroundWrapper>
        <HowItWorksSection />
        <NoWinNoFeeSection />
      </GreyBackgroundWrapper>
      <SettlementTimeSection />
      <TeamSection headline={"Sie möchten professionell gegen Ihre Kündigung angehen?"} subHeadline={"Wir kümmern uns persönlich um Sie"} />
      <TrustSection />
      <PartnersSection />
      {!isLoggedIn && (
        <Container>
          <DismissalCheckin idPrefix={"bottom"} partner={"wbs"} />
        </Container>
      )}
    </Layout>
  );
};

export default DismissalCheckWbsPage;
