import lineHorizontal from "../../assets/img/teaser/contact/Linie_gestrichelt.svg";
import lineVertical from "../../assets/img/teaser/contact/Linie_gestrichelt_mobile.svg";
import { Theme } from "@mui/material";

const tripleImageProgressionTeaserStyle = {
  stepsContainer: (theme: Theme) => ({
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  }),
  backgroundContainer: (theme: Theme) => ({
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("md")]: {
      backgroundImage: "url(" + lineVertical + ")",
      width: 360,
      height: 880,
    },
    [theme.breakpoints.up("md")]: {
      backgroundImage: "url(" + lineHorizontal + ")",
      width: 700,
      height: 260,
    },
  }),
  image: {
    width: 200,
    height: "auto",
  },
  spaceBetween: (theme: Theme) => ({
    [theme.breakpoints.down("md")]: {
      height: 50,
    },
    [theme.breakpoints.up("md")]: {
      width: 50,
    },
  }),
  step: {
    margin: "0 auto",
    width: 200,
    height: 260,
    textAlign: "center",
  },
};

export default tripleImageProgressionTeaserStyle;
