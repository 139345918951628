/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import _last from "lodash/last";
import _split from "lodash/split";
import _trimEnd from "lodash/trimEnd";

export const toRegisterLink = (pathname: string = "/registrierung", target: string = "/servicewelt") => ({
  pathname: pathname,
  state: { from: { pathname: target } },
});

export const getLastPathSplit = (pathname: string) => {
  return _last(_split(_trimEnd(pathname, "/"), "/"));
};
