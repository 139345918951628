/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import { scrollToAnchor } from "../../theme/commonStyle";
import { Theme } from "@mui/material";

const termsOfServiceStyle = {
  scrollToAnchor,
  termsOfServicePage: {
    marginTop: "97px",
  },
  title: (theme: Theme) => ({
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  }),
  indented: {
    paddingLeft: "2.5rem",
  },
};

export default termsOfServiceStyle;
