/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { getCaseLink } from "../../../../services/productService";
import { AbstractCase } from "../../../../types/Entities/AbstractCase";

type TrafficPaidProps = {
  product: AbstractCase;
};

const TrafficPaid = ({ product }: TrafficPaidProps) => {
  return (
    <>
      <p>
        Sie erhalten umgehend Feedback, sobald wir Ihre Akten erhalten haben und zu Ihrem Fall Stellung beziehen können. Alle Informationen zu Ihrem Fall finden
        Sie jederzeit in Ihrer Servicewelt unter "Meine Fälle".
      </p>
      <Button fullWidth variant={"contained"} color="primary" component={Link} to={getCaseLink(product)}>
        Zu meinem Fall
      </Button>
    </>
  );
};

export default TrafficPaid;
