// Google Tag Manager Configuration depending on Environment (developmemnt, test, production)

const TagManagerConfig = () => {
  let tagManagerArgs:tagManagerArgs = {
    gtmId: "GTM-WRLWSVS",
  };

  switch (process.env.REACT_APP_DEPLOYMENT) {
    case "production":
      // production environment
      tagManagerArgs.auth = "WxyzhJAXViYwH8nNEiDExw";
      tagManagerArgs.preview = "env-2";
      break;
    case "development":
      // dev environment
      tagManagerArgs.auth = "c6OlET7-18xb9h4rRlghQA";
      tagManagerArgs.preview = "env-6";
      break;
    case "testing":
      // staging environment
      tagManagerArgs.auth = "AkvV-p_VsIR1N89yvCpVOQ";
      tagManagerArgs.preview = "env-7";
      break;
    default:
      // dev environment
      tagManagerArgs.auth = "c6OlET7-18xb9h4rRlghQA";
      tagManagerArgs.preview = "env-6";
  }
  return tagManagerArgs;
};

type tagManagerArgs = {
  gtmId: string;
  auth?: string;
  preview?: string;
}

export default TagManagerConfig;
