/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import { Helmet } from "react-helmet-async";

import { gaSet } from "../../../../../services/cookieService";

const DismissalCheckAragPageMeta = () => {
  const title = "Kündigung erhalten? Jetzt prüfen und klagen! | Legalbird";
  gaSet({ title: title });

  return (
    <Helmet>
      <title>{title}</title>
      <meta
        name="description"
        content="Haben Sie eine Kündigung erhalten? Lassen Sie diese als Kunde der ARAG kostenlos online prüfen - ganz ohne Anwaltsbesuch!
      Professionell vom Anwalt für Arbeitsrecht beraten lassen!"
      />
      <meta name="keywords" content="ARAG, Kündigung, Kündigungsschutzklage, Anspruch auf Abfindung prüfen" />
      <meta name="robots" content="index,follow" />
      <meta property="og:title" content={title} />
      <meta property="og:locale" content="de_DE" />
      <meta property="og:type" content="website" />
      <meta
        property="og:description"
        content="Haben Sie eine Kündigung erhalten? Lassen Sie diese als Kunde der ARAG kostenlos online prüfen - ganz ohne Anwaltsbesuch!
      Professionell vom Anwalt für Arbeitsrecht beraten lassen!"
      />
    </Helmet>
  );
};

export default DismissalCheckAragPageMeta;
