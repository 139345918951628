import jwtDecode from "jwt-decode";
import { Customer } from "../types/Entities/Customer";

type DecodedToken = {
  username: string;
  roles: string[];
  exp: number;
};

type Token = string | null;

export const checkToken = (token: Token, userState: Customer | null = null): boolean => {
  if (typeof token !== "string" || token.length === 0 || !userState) {
    return false;
  }

  try {
    const decodedToken = jwtDecode<DecodedToken>(token);
    return (decodedToken.username === userState.email || decodedToken.roles.includes("ROLE_ADMIN")) && decodedToken.exp > Date.now() / 1000;
  } catch (err) {
    return false;
  }
};

export const isAdmin = (token: Token): boolean => {
  if (typeof token !== "string" || token.length === 0) {
    return false;
  }

  try {
    const decodedToken = jwtDecode<DecodedToken>(token);
    return decodedToken.exp > Date.now() / 1000 && decodedToken.roles.includes("ROLE_ADMIN");
  } catch (err) {
    return false;
  }
};

export const logoutLocalStorage = (): void => {
  const cookieConsent = localStorage.getItem("consent");
  const theme = localStorage.getItem("theme");
  const testKey = localStorage.getItem("testKey");

  localStorage.clear();

  if (cookieConsent) {
    localStorage.setItem("consent", cookieConsent);
  }

  if (theme) {
    localStorage.setItem("theme", theme);
  }

  if (testKey) {
    localStorage.setItem("testKey", testKey);
  }
};
