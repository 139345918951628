/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import Layout from "../../../../Layouts/Layout";
import SettlementTimeSection from "../../../../../components/Sections/Settlement/SettlementTimeSection/SettlementTimeSection";
import LpDismissalMeta from "./LpDismissalMeta";
import NoWinNoFeeSection from "../../../../../components/Sections/Settlement/NoWinNoFeeSection/NoWinNoFeeSection";
import LandingPageTeaser from "../../../../../components/Teaser/LandingPageTeaser";
import TrustSection from "../../../../../components/Sections/Generic/TrustSection/TrustSection";
import PartnersSection from "../../../../../components/Sections/Generic/PartnersSection/PartnersSection";
import HowItWorksSection from "../../../../../components/Sections/Settlement/HowItWorksSection/HowItWorksSection";
import { getLastPathSplit } from "../../../../../services/redirect";
import { landingPagesList } from "../../../../../services/landingPagesList";
import accordionContentDismissal from "../../../../../components/ControlledAccordion/accordionContentDismissal";
import ControlledAccordionSection from "../../../../../components/Sections/Generic/ControlledAccordionSection/ControlledAccordionSection";
import TeamSection from "../../../../../components/Sections/Generic/TeamSection/TeamSection";
import LinkedCardBox from "../../../../../components/LinkedCardBox/LinkedCardBox";
import { settlementChoiceCards } from "../../../../../services/landingPageService";
import Container from "../../../../../components/Container/Container";
import GreyBackgroundWrapper from "../../../../../components/BackgroundWrapper/GreyBackgroundWrapper";
import { useLocation } from "react-router-dom";
import { useCustomer } from "../../../../../provider/Customer/CustomerProvider";

const LpDismissal = () => {
  const location = useLocation();
  const lpKey = getLastPathSplit(location.pathname);
  const headline = (lpKey && landingPagesList[lpKey]?.headline) ?? "Abfindung bei Kündigung";
  const { isLoggedIn } = useCustomer();

  return (
    <Layout minimal={!isLoggedIn} clickableLogo={isLoggedIn} backgroundType={"landingpage"}>
      <LpDismissalMeta />
      <LandingPageTeaser headline={headline} subheadline={"Wie können wir Ihnen helfen?"} hasTeaserButton={false}>
        <LinkedCardBox linkedCards={settlementChoiceCards} />
      </LandingPageTeaser>
      <GreyBackgroundWrapper>
        <ControlledAccordionSection contentList={accordionContentDismissal} idPrefix={"top"} name={"DismissalFaq"} heading={"Häufige Fragen zur Kündigung"} />
        <HowItWorksSection />
      </GreyBackgroundWrapper>
      <NoWinNoFeeSection />
      <GreyBackgroundWrapper>
        <SettlementTimeSection />
        <TeamSection headline={"Sie möchten professionell gegen Ihre Kündigung angehen?"} subHeadline={"Wir kümmern uns persönlich um Sie"} />
      </GreyBackgroundWrapper>
      <TrustSection />
      <Container>
        <LinkedCardBox linkedCards={settlementChoiceCards} />
      </Container>
      <PartnersSection />
    </Layout>
  );
};

export default LpDismissal;
