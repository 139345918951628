import React, { useMemo } from "react";
import Layout from "../../../Layouts/Layout";
import LandingPageTeaser from "../../../../components/Teaser/LandingPageTeaser";
import PartnerLogoSection from "../../../../components/Sections/Generic/PartnerLogoSection/PartnerLogoSection";
import AttorneySection from "../../../../components/Sections/Divorce/DivorceAttorneySection/AttorneySection";
import HowItWorksSection from "../../../../components/Sections/FamilyLaw/Alimony/HowItWorksSection";
import Container from "../../../../components/Container/Container";
import AlimonyCheckin from "../../../../components/Checkin/FamilyLawCheckin/Alimony/AlimonyCheckin";
import GreyBackgroundWrapper from "../../../../components/BackgroundWrapper/GreyBackgroundWrapper";
import LinkedCardBox from "../../../../components/LinkedCardBox/LinkedCardBox";
import { alimonyTeaserCards } from "../../../../services/landingPageService";
import LpAlimonyAdvoCardMeta from "./AdvoCard/LpAlimonyAdvoCardMeta";
import LpAlimonyAragMeta from "./Arag/LpAlimonyAragMeta";
import LpAlimonyRolandMeta from "./Roland/LpAlimonyRolandMeta";
import { useCustomer } from "../../../../provider/Customer/CustomerProvider";
import { AbstractCase } from "../../../../types/Entities/AbstractCase";

const getMetaDataByPartner = ({ partner }: { partner: string }) => {
  switch (partner) {
    case "advocard":
      return <LpAlimonyAdvoCardMeta />;
    case "arag":
      return <LpAlimonyAragMeta />;
    case "roland":
      return <LpAlimonyRolandMeta />;
    default:
      return null;
  }
};

const LpAlimonyPartner = ({ partner }: { partner: NonNullable<AbstractCase["acquisitionPartner"]> }) => {
  const { isLoggedIn } = useCustomer();

  const metaData = useMemo(() => getMetaDataByPartner({ partner }), [partner]);

  return (
    <Layout minimal={!isLoggedIn} clickableLogo={isLoggedIn} backgroundType={"landingpage"}>
      {metaData}
      <LandingPageTeaser
        headline={"Beratung zum Thema Unterhalt"}
        subheadline={"Unterhalt berechnen und professionell beraten lassen"}
        teaserButtonText={"Unterhalt berechnen"}
        teaserButtonLink={isLoggedIn ? "/servicewelt" : "#top-checkin"}
      >
        <LinkedCardBox linkedCards={alimonyTeaserCards} />
      </LandingPageTeaser>
      <PartnerLogoSection partnerName={partner} containerStyle={"settlementContainer"} />
      <GreyBackgroundWrapper>
        {!isLoggedIn && (
          <Container>
            <AlimonyCheckin checkinHeading={"Unterhaltsberechnung erstellen und Erstberatung vereinbaren"} idPrefix={"top"} partner={partner} />
          </Container>
        )}
        <HowItWorksSection />
      </GreyBackgroundWrapper>
      <AttorneySection
        product={"familyLawCase"}
        textCards={false}
        subheading={"Sie möchten sich professionell und schnell unterstützen lassen? Wir helfen Ihnen - jederzeit und ortsunabhängig!"}
      />
      {!isLoggedIn && (
        <Container>
          <AlimonyCheckin checkinHeading={"Unterhaltsberechnung erstellen und Erstberatung vereinbaren"} idPrefix={"top"} partner={partner} />
        </Container>
      )}
    </Layout>
  );
};

export default LpAlimonyPartner;
