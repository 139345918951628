/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import { Helmet } from "react-helmet-async";
import { gaSet } from "../../../../../services/cookieService";

const LpFamilyLawConsultingAdvoCardMeta = () => {
  const title = "Familienrecht | Legalbird";
  gaSet({ title: title });

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content="Lassen Sie sich als ADVOCARD Kunde bei Legalbird beraten - schnell, einfach und nirgendwo günstiger!" />
      <meta name="keywords" content="Familienrecht, Beratung" />
      <meta name="robots" content="noindex,nofollow" />
      <meta property="og:title" content={title} />
      <meta property="og:locale" content="de_DE" />
      <meta property="og:type" content="website" />
      <meta property="og:description" content="Lassen Sie sich als ADVOCARD Kunde bei Legalbird beraten - schnell, einfach und nirgendwo günstiger!" />
    </Helmet>
  );
};

export default LpFamilyLawConsultingAdvoCardMeta;
