import React, { ReactElement } from "react";
import ContentBox from "../../Box/ContentBox/ContentBox";
import statusIcon from "../../../assets/icons/serviceOverview/IconStatus";
import Status from "../../Status/Status";
import { getStatusBoxConfig } from "../../../services/serviceWorldService";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import { ReactNodeLike } from "prop-types";

type StatusBoxProps = {
  product: AbstractCase;
};

const StatusBox = ({ product }: StatusBoxProps) => {
  if (!product) {
    return null;
  }

  //todo replace with statusConfig after serviceWorldService refactor
  const statusConfig: StatusConfig = getStatusBoxConfig(product);

  const ExtraContent = () => statusConfig.extraContent || null;

  return (
    <ContentBox icon={statusIcon} headline={"Status"} alignContent={"stretch"}>
      <Status
        ctaButton={statusConfig.ctaButton}
        maxPercentage={statusConfig.percentage}
        nextStepContent={{
          header: statusConfig.header,
          text: statusConfig.text,
        }}
      />
      <ExtraContent />
    </ContentBox>
  );
};

type StatusConfig = {
  header: string;
  text: string;
  ctaButton: ReactNodeLike;
  productName: AbstractCase["name"]
  percentage: number;
  extraContent?: ReactElement | null;
};

export default StatusBox;
