/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

const loginIconStyle = {
  modalContainer: {
    paddingTop: "2rem",
  },
};

export default loginIconStyle;
