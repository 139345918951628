import React, { ReactNode } from "react";
import gradientPaperStyle from "./gradientPaperStyle";
import { Paper, PaperProps, Theme } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { SxProps } from "@mui/system";

type GradientPaperProps = {
  sx?: SxProps<Theme>;
  children: ReactNode;
} & PaperProps;

export default function GradientPaper({ sx = {}, children, ...rest }: GradientPaperProps) {
  const theme = useTheme();

  return (
    <Paper sx={{ ...gradientPaperStyle.background(theme), ...sx }} {...rest}>
      {children}
    </Paper>
  );
}
