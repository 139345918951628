import React, { ReactNode } from "react";
import gradientPaperStyle from "./gradientPaperStyle";
import { Card } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import { SxProps } from "@mui/system";

type GradientCardProps = {
  sx: SxProps<Theme>;
  children: ReactNode;
};
export default function GradientCard({ sx = {}, children, ...rest }: GradientCardProps) {
  const theme = useTheme();

  return (
    <Card sx={{ ...gradientPaperStyle.background(theme), ...sx }} {...rest}>
      {children}
    </Card>
  );
}
