import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormProvider } from "../../provider/Form/FormProvider";
import LoginActionForm from "./LoginActionForm";
import * as loginActions from "../../store/login/actions";
import { login, register } from "../../store/login/actions";
import _get from "lodash/get";
import * as messagesActions from "../../store/messages/actions";
import { useLocation, useNavigate } from "react-router";
import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../services/reactQuery/reactQueryService";
import { apiGet, getToken } from "../../services/apiClient";

type LoginActionProps = {
  setLoginLayerTitle?: Function;
  defaultAction?: "login" | "recover" | "register";
  showPostfix?: boolean;
};

export default function LoginAction({ setLoginLayerTitle = () => {}, defaultAction = "login", showPostfix = true }: LoginActionProps) {
  const [actionType, setActionType] = useState<LoginActionProps["defaultAction"]>(defaultAction);
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const titles = {
      login: "Anmelden",
      recover: "Passwort vergessen?",
      register: "Registrieren",
    };
    setLoginLayerTitle(titles[actionType || defaultAction]);
  }, [actionType]);

  const initialValues = {
    email: location?.state?.email || "",
    password: "",
    gender: "",
    familyName: "",
    givenName: "",
  };

  const handleLogin = async (values: Record<string, any>) => {
    setIsLoading(true);
    try {
      await getToken({ email: values.email.toLowerCase(), password: values.password });
      await queryClient.invalidateQueries(queryKeys.collection("/customers"));
      const from = _get(location, "state.from", { pathname: "/servicewelt", search: "" });
      navigate(from.pathname + from.search);
    } catch (e) {
      messagesActions.addMessage({
        type: "warning",
        text: "Für diese Kombination aus E-Mail-Adresse und Passwort konnten keine Daten gefunden werden. Bitte überprüfen Sie Ihre eingegebenen Daten.",
      });
      setIsLoading(false);
    }
  };

  const handleRegister = async (values: Record<string, any>) => {
    setIsLoading(true);
    const emailCheck = await loginActions.checkEmail(values.email.toLowerCase());
    if (emailCheck.hasError) {
      setIsLoading(false);
      messagesActions.addMessage({
        type: "info",
        text: "Es existiert bereits ein Konto mit dieser E-Mail-Adresse. Sie können sich mit Ihrem Passwort einloggen.",
      });
      setActionType("login");
      return;
    }

    const data = {
      email: values.email.toLowerCase(),
      person: {
        givenName: values.givenName,
        familyName: values.familyName,
      },
      prelead: {
        initialProduct: "generic",
        landingPage: "RegisterBox",
      },
    };

    try {
      await register(data);
      await queryClient.invalidateQueries(queryKeys.collection("/customers"));
      const from = _get(location, "state.from", { pathname: "/servicewelt", search: "" });
      navigate(from.pathname + from.search);
    } catch (e) {
      messagesActions.addMessage({
        type: "error",
        text: "Bei Ihrer Registrierung ist ein Fehler aufgetreten.",
      });
      setIsLoading(false);
    }
  };

  const handleRecover = async (values: Record<string, any>) => {
    setIsLoading(true);
    try {
      await apiGet("/customers/password_reset", { email: values.email.toLowerCase() });
      messagesActions.addMessage({
        type: "success",
        text: "Wir haben Ihnen eine E-Mail zum Zurücksetzen Ihres Passworts geschickt.",
      });
      setActionType("login");
    } catch (e) {
      messagesActions.addMessage({
        type: "error",
        text: "Beim Zurücksetzen Ihres Passworts ist ein Fehler aufgetreten.",
      });
    }
    setIsLoading(false);
  };

  const submitAction = async ({ values }: { values: Record<string, any> }) => {
    switch (actionType) {
      case "login":
        await handleLogin(values);
        return;
      case "register":
        await handleRegister(values);
        return;
      case "recover":
        await handleRecover(values);
        return;
    }
  };

  return (
    <FormProvider initialValues={initialValues} submitAction={submitAction}>
      <LoginActionForm actionType={actionType} setActionType={setActionType} isLoading={isLoading} showPostfix={showPostfix} />
    </FormProvider>
  );
}

LoginAction.propTypes = {
  defaultAction: PropTypes.oneOf(["login", "register", "recover"]),
};
