const pictureTeaserCardStyle = {
  image: {
    width: "100%",
    aspectRatio: "1 / 1",
    objectFit: "cover",
    objectPosition: "center",
    borderRadius: "25px 25px 0 0",
  },
  card: {
    width: "250px",
    height: "320px",
    margin: "1rem auto",
  },
  text: {
    marginTop: ".3rem",
    textAlign: "center",
  },
};

export default pictureTeaserCardStyle;
