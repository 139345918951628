/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import Layout from "../../../containers/Layouts/Layout";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "../../Container/Container";
import TextCard from "../../Cards/TextCard/TextCard";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";

const GeneralCheck = () => {
  return (
    <Layout>
      <Container>
        <TextCard boxHeading={"Ihr Dokument wird erstellt..."} headingColor={"primary"} minHeadingHeight={70}>
          <Grid container justifyContent={"center"}>
            <Grid component={CircularProgress} item size={100} />
          </Grid>
          <Typography sx={{ textAlign: "center" }}>Dies kann einen kurzen Moment dauern.</Typography>
        </TextCard>
      </Container>
    </Layout>
  );
};

export default GeneralCheck;
