import React from "react";
import serviceworldStepSectionStyle from "./serviceworldStepSectionStyle";
import HeadlineSection from "../HeadlineSection/HeadlineSection";
import { Box, Button, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import TripleImageProgressionTeaser from "../../../TripleImageProgressionTeaser/TripleImageProgressionTeaser";
import couchImage from "../../../../assets/img/teaser/contact/sign_up.svg";
import couchImageDark from "../../../../assets/img/teaser/contact/sign_up_dark.svg";
import workTogetherImage from "../../../../assets/img/teaser/contact/work_together.svg";
import workTogetherImageDark from "../../../../assets/img/teaser/contact/work_together_dark.svg";
import checklistImage from "../../../../assets/img/teaser/contact/checklist.svg";
import checklistImageDark from "../../../../assets/img/teaser/contact/checklist_dark.svg";
import Container from "../../../Container/Container";
import { Link } from "react-router-dom";
import ThemedIllustration from "../../../ThemedIconSwitch/ThemedIllustration";

export default function ServiceworldStepSection({}) {
  return (
    <Container>
      <Stack alignItems={"center"}>
        <HeadlineSection headline={"Ihre Servicewelt"} typographyVariant={"h2"} />
        <Typography variant={"subtitle1"} sx={serviceworldStepSectionStyle.centerText}>
          In unserer Servicewelt können Sie in wenigen einfachen Schritten Ihre Fälle anlegen und jederzeit den aktuellen Stand einsehen.
        </Typography>
        <Box sx={serviceworldStepSectionStyle.marginTop}>
          <TripleImageProgressionTeaser
            step1={{
              headline: "Anmelden",
              subHeadline: "Registrieren Sie sich einfach in unserer Servicewelt",
              image: <ThemedIllustration lightIllustration={couchImage} darkIllustration={couchImageDark} />,
            }}
            step2={{
              headline: "Daten angeben",
              subHeadline: "Geben Sie dort Ihre fallrelevanten Daten ein",
              image: <ThemedIllustration lightIllustration={checklistImage} darkIllustration={checklistImageDark} />,
            }}
            step3={{
              headline: "Bearbeitung",
              subHeadline: "Unsere Anwälte lösen im engen Kontakt Ihren Rechtsfall",
              image: <ThemedIllustration lightIllustration={workTogetherImage} darkIllustration={workTogetherImageDark} />,
            }}
          />
        </Box>
        <Button variant={"contained"} color={"primary"} component={Link} to={"/servicewelt"} sx={serviceworldStepSectionStyle.button}>
          Zu Ihrer Servicewelt
        </Button>
      </Stack>
    </Container>
  );
}
