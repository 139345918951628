import React, { ComponentType } from "react";
import serviceWorldCaseItemStyle from "./serviceWorldCaseItemStyle";
import Divider from "@mui/material/Divider";
import { Grid, ListItemButton } from "@mui/material";
import { Link } from "react-router-dom";
import { getProductData } from "../../../services/productService";
import Typography from "@mui/material/Typography";
import IconWrapper from "../../IconWrapper/IconWrapper";
import { translate } from "../../../services/translations/translations";
import { MinimalCase } from "../../../types/Entities/MinimalCase";

type ServiceWorldCaseItemProps = {
  minimalCase: MinimalCase;
  withDivider?: boolean;
  NotificationComponent?: ComponentType<{ minimalCase: MinimalCase }> | null;
  listItemButtonConfig?: { getLink?: Function; getIsSelected?: Function; handleClick?: Function };
};

const ServiceWorldCaseItem = ({ minimalCase, withDivider = true, listItemButtonConfig = {}, NotificationComponent = null }: ServiceWorldCaseItemProps) => {
  const isLink = !!listItemButtonConfig.getLink;

  let conditionalProps: ConditionalConfig = {};

  if (isLink && listItemButtonConfig?.getLink) {
    conditionalProps.component = Link;
    conditionalProps.to = listItemButtonConfig.getLink(minimalCase);
  }

  if (!!listItemButtonConfig.getIsSelected) {
    conditionalProps.selected = listItemButtonConfig.getIsSelected(minimalCase);
  }

  if (!!listItemButtonConfig.handleClick) {
    conditionalProps.onClick = () => listItemButtonConfig.handleClick?.(minimalCase);
  }

  return (
    <>
      {withDivider && <Divider sx={serviceWorldCaseItemStyle.divider} />}
      <ListItemButton {...conditionalProps} sx={serviceWorldCaseItemStyle.caseItem}>
        <Typography sx={serviceWorldCaseItemStyle.reference}>{minimalCase.reference}</Typography>
        <Grid container alignItems={"center"} sx={serviceWorldCaseItemStyle.innerContainer} wrap={"nowrap"}>
          <Grid item>
            <IconWrapper icon={getProductData(minimalCase.name, "productCategory.icon")} sx={serviceWorldCaseItemStyle.icon} alt={"Produkt Icon"} />
          </Grid>
          <Grid item sx={serviceWorldCaseItemStyle.textContainer}>
            <Typography variant={"h6"}>
              {getProductData(minimalCase.name, "productCategory.label")}
              {(minimalCase.instanceLevel || 0) > 0 ? " - " + translate(minimalCase.name + ".instanceLevel", minimalCase) : null}
            </Typography>
            <Typography>
              {getProductData(minimalCase.name, "label")}
              {minimalCase.opposingParty ? ` - ${minimalCase.opposingParty}` : ""}
            </Typography>
          </Grid>
          {NotificationComponent && <NotificationComponent minimalCase={minimalCase} />}
        </Grid>
      </ListItemButton>
    </>
  );
};

type ConditionalConfig = {
  component?: typeof Link;
  to?: string;
  selected?: boolean;
  onClick?: () => void;
};

export default ServiceWorldCaseItem;
