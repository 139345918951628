import React from "react";
import processBoxStyle from "./processBoxStyle";
import { Grid, Typography } from "@mui/material";
import _map from "lodash/map";
import { getProcessConfig } from "../../../services/serviceWorldService";
import IconCheckCircleBig from "../../../assets/icons/serviceOverview/IconCheckCircleBig";
import IconProgress from "../../../assets/icons/serviceOverview/IconProgress";
import { ProcessBoxLoader } from "../../ContentLoader/ProcessBoxLoader";
import ContentBox from "../../Box/ContentBox/ContentBox";
import IconWrapper from "../../IconWrapper/IconWrapper";
import { AbstractCase } from "../../../types/Entities/AbstractCase";

type ProcessBoxProp = {
  product: AbstractCase;
};

const ProcessBox = ({ product }: ProcessBoxProp) => {
  if (!product) {
    return (
      <ContentBox icon={IconProgress} headline={"Prozessablauf"} alignContent={"stretch"} loading={!product}>
        <ProcessBoxLoader />
      </ContentBox>
    );
  }

  if (product.isCustomerDataCompleted) {
    return null;
  }

  const processConfig = getProcessConfig(product);

  if (!processConfig) {
    return null;
  }

  return (
    <ContentBox icon={IconProgress} headline={processConfig.headline} alignContent={"stretch"} loading={!product}>
      <Grid container>
        {_map(processConfig.steps, (step, index: number) => (
          <React.Fragment key={index}>
            <Grid item xs={2}>
              <Grid container sx={processBoxStyle.iconContainer} direction="column" alignItems="center">
                {index === 0 ? <Grid item sx={processBoxStyle.connectorLineInvisible} /> : <Grid item sx={processBoxStyle.connectorLine} />}
                <Grid item sx={processBoxStyle.progressIcons}>
                  {step.done ? (
                    <IconCheckCircleBig sx={processBoxStyle.progressIcons} />
                  ) : (
                    <IconWrapper icon={step.icon} alt="Step Icon" sx={processBoxStyle.progressIcons} />
                  )}
                </Grid>
                {index === processConfig.steps.length - 1 ? (
                  <Grid item sx={processBoxStyle.connectorLineInvisible} />
                ) : (
                  <Grid item sx={processBoxStyle.connectorLine} />
                )}
              </Grid>
            </Grid>
            <Grid item xs={10} sx={[processBoxStyle.textBlock, step.done ? processBoxStyle.done : null]}>
              <Typography sx={processBoxStyle.stepHeading}>{step.headline}</Typography>
              <Typography sx={processBoxStyle.stepText}>{step.content}</Typography>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </ContentBox>
  );
};

export default ProcessBox;
