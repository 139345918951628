/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import Button from "@mui/material/Button";
import _isEmpty from "lodash/isEmpty";
import logoStyle from "./logoStyle";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

type LogoProps = {
  size?: "medium" | "large";
  target: string;
  isClickable?: boolean;
};

const Logo = ({ size = "medium", target, isClickable = true, ...rest }: LogoProps) => {
  const theme = useTheme();

  const buttonStyle = {
    ...logoStyle.logo,
    ...logoStyle.generic(theme),
    ...logoStyle[size],
  };

  return _isEmpty(target) || !isClickable ? (
    <Button {...rest} disabled sx={buttonStyle}>
      {" "}
    </Button>
  ) : (
    <Button {...rest} sx={buttonStyle} component={Link} to={target}>
      {" "}
    </Button>
  );
};

export default Logo;
