/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React, { useEffect } from "react";
import PoAVerificationModal from "../../../../components/FormInfoModal/PoAVerificationModal";
import Layout from "../../../Layouts/Layout";
import { getProductData } from "../../../../services/productService";
import ApplicationForm from "../../../../components/ApplicationForm/ApplicationForm";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createResource, fetchCollection, fetchResource, queryKeys } from "../../../../services/reactQuery/reactQueryService";
import useProductParams from "../../../../hooks/useProductParams";
import LegalbirdLoaderPage from "../../Loading/LegalbirdLoaderPage";
import { AbstractCase } from "../../../../types/Entities/AbstractCase";

const ProcessCostSupportFormPage = () => {
  const { productId } = useProductParams();
  const productApiUri = getProductData("divorce", "apiUri");
  const { data: divorce, isLoading } = useQuery(queryKeys.item(productApiUri, productId), () => fetchResource(productApiUri, productId));

  return isLoading || !divorce ? <LegalbirdLoaderPage /> : <ProcessCostSupportCollectionProvider divorce={divorce} />;
};

type ProcessCostSupportCollectionProviderProps = {
  divorce: AbstractCase;
};

const ProcessCostSupportCollectionProvider = ({ divorce }: ProcessCostSupportCollectionProviderProps) => {
  const { data: processCostSupportsOfCustomer = [], isLoading } = useQuery(queryKeys.collection("/process_cost_supports"), () =>
    fetchCollection("/process_cost_supports")
  );
  const queryClient = useQueryClient();

  const createPCSMutation = useMutation(createResource, {
    onSuccess: (data) => queryClient.setQueryData(queryKeys.collection("/process_cost_supports"), [data]),
  });

  useEffect(() => {
    if (divorce && !isLoading && processCostSupportsOfCustomer.length === 0) {
      createPCSMutation.mutate({ uri: "/process_cost_supports", data: { divorce: "/divorces/" + divorce.id } });
    }
  }, [processCostSupportsOfCustomer]);

  return isLoading || processCostSupportsOfCustomer.length === 0 ? (
    <LegalbirdLoaderPage />
  ) : (
    <ProcessCostSupportFormPageInner processCostSupportId={processCostSupportsOfCustomer[0].id} divorce={divorce} />
  );
};

type ProcessCostSupportFormPageInnerProps = {
  processCostSupportId: number;
  divorce: AbstractCase;
};

const ProcessCostSupportFormPageInner = ({ processCostSupportId, divorce }: ProcessCostSupportFormPageInnerProps) => {
  const { data: processCostSupport, isLoading } = useQuery(queryKeys.item("/process_cost_supports", processCostSupportId), () =>
    fetchResource("/process_cost_supports", processCostSupportId)
  );

  if (isLoading || !processCostSupport) {
    return <LegalbirdLoaderPage />;
  }

  return (
    <Layout>
      <ApplicationForm product={processCostSupport} />
      <PoAVerificationModal divorce={divorce} />
    </Layout>
  );
};

export default ProcessCostSupportFormPage;
