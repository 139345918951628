import React, { useState } from "react";
import InquiryToCustomer, { RequestedDocument } from "../../../types/Entities/InquiryToCustomer";
import { Checkbox, Divider, Grid, Stack, Typography } from "@mui/material";
import ValidatorTextField from "../../FormFields/ValidatorTextField";
import { fileCheck, formValue, saveMediaObject } from "../../../services/formServiceFunctions";
import FormControlLabel from "@mui/material/FormControlLabel";
import FileUploadForm from "../../Form/Common/FileUploadSection/FileUploadForm";
import * as messagesActions from "../../../store/messages/actions";
import _head from "lodash/head";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createMediaObject } from "../../../services/reactQuery/reactQueryMediaObjectService";
import { AbstractCase } from "../../../types/Entities/AbstractCase";
import { queryKeys, updateResource } from "../../../services/reactQuery/reactQueryService";
import { MediaObject } from "../../../types/Entities/MediaObject/MediaObject";
import { useForm } from "../../../provider/Form/FormProvider";
import structuredClone from "@ungap/structured-clone";
import { allowMissingDocument } from "../../../services/inquiryToCustomerService";

type InquiryToCustomerDocumentsBoxProps = {
  inquiryToCustomer: InquiryToCustomer;
  product: AbstractCase;
};

export default function InquiryToCustomerDocumentsBox({ inquiryToCustomer, product }: InquiryToCustomerDocumentsBoxProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [filePath, setFilePath] = useState("");
  const createMediaObjectMutation = useMutation(createMediaObject);
  const queryClient = useQueryClient();

  const inquiryApiUri = "/inquiry_to_customers";

  const updateMutation = useMutation(updateResource, {
    onSuccess: (data) => queryClient.setQueryData(queryKeys.item(inquiryApiUri, inquiryToCustomer.id), data),
  });
  const { handleChange, values } = useForm();

  if (!inquiryToCustomer.requestedDocuments) {
    return null;
  }

  const updateInquiryToCustomerMediaObjects = async (requestedDocuments: Array<RequestedDocument>) => {
    await updateMutation.mutateAsync({
      uri: inquiryApiUri,
      id: inquiryToCustomer.id,
      data: {
        requestedDocuments: requestedDocuments,
      },
    });
  };

  const deleteMediaObject = async (mediaObjectToDelete: MediaObject, index: number) => {
    if (!inquiryToCustomer.requestedDocuments) {
      return;
    }
    setIsLoading(true);
    let requestedDocumentsClone = structuredClone(inquiryToCustomer.requestedDocuments);
    requestedDocumentsClone[index].temporaryFilesToCombine = requestedDocumentsClone[index].temporaryFilesToCombine.filter(
      (mediaObject) => mediaObject.id !== mediaObjectToDelete.id
    );
    await updateInquiryToCustomerMediaObjects(requestedDocumentsClone);
    setIsLoading(false);
  };

  const handleMediaObjectUpload = async (target: HTMLInputElement, index: number) => {
    if (!inquiryToCustomer.requestedDocuments) {
      return;
    }
    setIsLoading(true);
    const fileCheckRes = fileCheck(target);
    if (fileCheckRes.hasError) {
      messagesActions.addMessage({ type: "error", text: fileCheckRes.errorMsg });
      return;
    }
    const file = _head(target.files)!;
    //TODO improve. hacked because of deadline
    setFilePath(file ? index + file.name : index + "");

    const mediaObject = await saveMediaObject(createMediaObjectMutation, {
      file: file,
      fieldname: "inquiryTemporaryFile",
      description: "TODO",
      product: product.name,
      productId: product.id,
    });

    if (mediaObject) {
      let requestedDocumentsClone = structuredClone(inquiryToCustomer.requestedDocuments);

      requestedDocumentsClone[index].temporaryFilesToCombine = [...requestedDocumentsClone[index].temporaryFilesToCombine, mediaObject];
      await updateInquiryToCustomerMediaObjects(requestedDocumentsClone);
    }

    setFilePath("");
    setIsLoading(false);
  };

  return (
    <Stack spacing={2}>
      <Typography variant={"h4"} sx={{ textAlign: "center" }}>
        Benötigte Dokumente
      </Typography>
      {!!inquiryToCustomer.inquiryTextDocuments && (
        <>
          <Typography sx={{ whiteSpace: "pre-wrap" }}>
            <strong>
              <u>Wichtige Hinweise zu den benötigten Dokumenten:</u>
            </strong>
          </Typography>
          <Typography sx={{ color: "text.primary" }} dangerouslySetInnerHTML={{ __html: inquiryToCustomer.inquiryTextDocuments }} />
        </>
      )}
      {inquiryToCustomer.requestedDocuments.map((document, index) => {
        return (
          <>
            <Grid container key={index}>
              <Grid item xs={12} md={6}>
                <Typography variant={"h5"}>{document.documentName}</Typography>
                {allowMissingDocument(document, inquiryToCustomer) && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formValue(values, "requestedDocumentMissing" + index)}
                        name={"requestedDocumentMissing" + index}
                        onChange={handleChange}
                      />
                    }
                    label={"Dieses Dokument liegt mir leider nicht vor"}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} sx={{ paddingTop: "2rem" }}>
                <FileUploadForm
                  mediaObjectCollection={document.temporaryFilesToCombine}
                  deleteMediaObject={(mediaObjectToDelete: MediaObject) => deleteMediaObject(mediaObjectToDelete, index)}
                  handleFileChange={({ target }: any) => handleMediaObjectUpload(target, index)}
                  fieldname={"inquiryTemporaryFile" + index}
                  allowMore={document.temporaryFilesToCombine.length < 1 || document.temporaryFilesToCombine[0].mimeType !== "application/pdf"}
                  filePath={filePath.substring(0, 1) === index + "" ? filePath.substring(1) : ""}
                  isBlocked={isLoading || formValue(values, "requestedDocumentMissing" + index)}
                  label={""}
                  helperText={undefined}
                  noSection={undefined}
                  headline={undefined}
                />
              </Grid>
            </Grid>
            {index + 1 !== inquiryToCustomer.requestedDocuments?.length && <Divider />}
          </>
        );
      })}
      {!inquiryToCustomer.hideInquiryTextDocumentsCustomer && (
        <ValidatorTextField
          label="Ihre Anmerkungen und Fragen (optional)"
          name="inquiryTextDocumentsCustomer"
          placeholder={"Sie haben Anmerkungen oder Fragen zu den angefragten Dokumenten, dann teilen Sie uns diese gerne kurz hier mit."}
          multiline
          rows={5}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    </Stack>
  );
}
