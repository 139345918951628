import React, { useMemo } from "react";
import Layout from "../../../Layouts/Layout";
import LandingPageTeaser from "../../../../components/Teaser/LandingPageTeaser";
import FamilyLawTeaserCardSection from "../../../../components/Sections/FamilyLaw/FamilyLawTeaserCardSection/FamilyLawTeaserCardSection";
import PartnerLogoSection from "../../../../components/Sections/Generic/PartnerLogoSection/PartnerLogoSection";
import HowItWorksSection from "../../../../components/Sections/FamilyLaw/HowItWorksSection";
import AttorneySection from "../../../../components/Sections/Divorce/DivorceAttorneySection/AttorneySection";
import LpFamilyLawAdvoCardMeta from "./AdvoCard/LpFamilyLawAdvoCardMeta";
import LpFamilyLawAragMeta from "./Arag/LpFamilyLawAragMeta";
import LpFamilyLawRolandMeta from "./Roland/LpFamilyLawRolandMeta";
import GreyBackgroundWrapper from "../../../../components/BackgroundWrapper/GreyBackgroundWrapper";
import { useCustomer } from "../../../../provider/Customer/CustomerProvider";
import { AbstractCase } from "../../../../types/Entities/AbstractCase";

const getMetaDataByPartner = ({ partner }: { partner: string }) => {
  switch (partner) {
    case "advocard":
      return <LpFamilyLawAdvoCardMeta />;
    case "arag":
      return <LpFamilyLawAragMeta />;
    case "roland":
      return <LpFamilyLawRolandMeta />;
    default:
      return null;
  }
};

const LpFamilyLawPartner = ({ partner }: { partner: NonNullable<AbstractCase["acquisitionPartner"]> }) => {
  const { isLoggedIn } = useCustomer();
  const metaData = useMemo(() => getMetaDataByPartner({ partner }), [partner]);

  return (
    <Layout minimal={!isLoggedIn} clickableLogo={isLoggedIn} backgroundType={"landingpage"}>
      {metaData}
      <LandingPageTeaser headline={"Professionelle Hilfe im Familienrecht"} subheadline={"Wo können wir Sie unterstützen?"} hasTeaserButton={false} />
      {/*here because the cards need to be outsite of the container since their combined width is too much otherwise*/}
      <FamilyLawTeaserCardSection partner={partner} />
      <PartnerLogoSection partnerName={partner} containerStyle={"settlementContainer"} />
      <GreyBackgroundWrapper>
        <HowItWorksSection />
        <AttorneySection
          product={"familyLawCase"}
          textCards={false}
          subheading={"Sie möchten sich professionell und schnell unterstützen lassen? Wir helfen Ihnen - jederzeit und ortsunabhängig!"}
        />
      </GreyBackgroundWrapper>
      <FamilyLawTeaserCardSection partner={partner} heading={"Wo können wir Sie unterstützen?"} />
    </Layout>
  );
};

export default LpFamilyLawPartner;
