/*
 * Copyright © 2024. Legalbird eine Marke der R&S Internet Jewels GmbH
 */

import React from "react";
import serviceWorldStyle from "../serviceWorldStyle";
import EmailConfirmationBox from "../Common/EmailConfirmationBox";
import ServiceWorldMyCases from "../MyCases/ServiceWorldMyCases";
import ActiveCaseInquiry from "../MyCases/ActiveCaseInquiry";
import { getCaseLink } from "../../../services/productService";
import {Box} from "@mui/material";
import { MinimalCase } from "../../../types/Entities/MinimalCase";

const ServiceWorld = () => {
  return (
    <Box sx={serviceWorldStyle.contentContainer}>
      <EmailConfirmationBox />
      <ServiceWorldMyCases NotificationComponent={ActiveCaseInquiry} listItemButtonConfig={{ getLink: (minimalCase: MinimalCase) => getCaseLink(minimalCase) }} />
    </Box>
  );
};

export default ServiceWorld;
