import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { getCaseLink, getProductData, getProductNameBy } from "../../../services/productService";
import { Grid } from "@mui/material";
import LegalbirdLoader from "../../../components/ContentLoader/LegalbirdLoader";
import Layout from "../../Layouts/Layout";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createResource, queryKeys } from "../../../services/reactQuery/reactQueryService";

const NewCasePage = () => {
  const { productName } = useParams();
  const navigate = useNavigate();
  const productClassName = getProductNameBy("urlPartShort", productName);
  const productApiUri = getProductData(productClassName, "apiUri");

  const queryClient = useQueryClient();
  const createMutation = useMutation(createResource, {
    onSuccess: (data, variables) => queryClient.setQueryData(queryKeys.item(variables.uri, data.id), data)
  });

  let isBlocked = false;
  useEffect(() => {
    if (!isBlocked) {
      isBlocked = true;
      createMutation.mutateAsync({ uri: productApiUri, data: {} }).then((product) => {
        queryClient.invalidateQueries(queryKeys.collection("/collection/cases"));
        navigate(getCaseLink(product) + "/antrag", { replace: true });
      });
    }
  }, [productApiUri]);

  return (
    <Layout>
      <Grid container justifyContent={"center"} style={{ marginTop: 50 }}>
        <Grid item>
          <LegalbirdLoader />
        </Grid>
      </Grid>
    </Layout>
  );
};
export default NewCasePage;
