/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import { Helmet } from "react-helmet-async";
import Container from "../../../../components/Container/Container";
import Layout from "../../../Layouts/Layout";
import React, { useEffect, useState } from "react";
import HintBox from "../../../../components/Box/HintBox/HintBox";
import FileUploadSection from "../../../../components/Form/Common/FileUploadSection/FileUploadSection";
import LegalbirdLoader from "../../../../components/ContentLoader/LegalbirdLoader";
import TextContainer from "../../../../components/Container/TextContainer";
import ButtonLoading from "../../../../components/Button/ButtonLoading";
import requestDocumentsPageStyle from "./requestDocumentsPageStyle";
import Paper from "@mui/material/Paper";
import ApiClient from "../../../../services/apiClient";
import { useNavigate } from "react-router-dom";
import { getCaseLink, getProductData } from "../../../../services/productService";
import { useQuery } from "@tanstack/react-query";
import { fetchResource, queryKeys } from "../../../../services/reactQuery/reactQueryService";
import useProductParams from "../../../../hooks/useProductParams";
import useMediaObjectCombinationRequest from "../../../../hooks/useMediaObjectCombinationRequest";
import { AbstractCase } from "../../../../types/Entities/AbstractCase";

const RequestDocumentsPage = () => {
  const { productId } = useProductParams();
  const productApiUri = getProductData("settlement", "apiUri");
  const { data: settlement, isLoading } = useQuery(queryKeys.item(productApiUri, productId), () => fetchResource(productApiUri, productId));

  if (isLoading) {
    return <LegalbirdLoader />;
  }
  return <RequestDocumentsPageInner settlement={settlement} />;
};

type RequestDocumentsPageInnerProps = {
  settlement: AbstractCase;
};
const RequestDocumentsPageInner = ({ settlement }: RequestDocumentsPageInnerProps) => {
  const [isBlocked, setIsBlocked] = useState(false);
  const [isLoadingInternal, setIsLoadingInternal] = useState(false);

  let mediaObjectCombinationRequestTypes = [];

  if (settlement.contract === null) {
    mediaObjectCombinationRequestTypes.push("contractFiles");
  }
  if (settlement.contractTermination === null) {
    mediaObjectCombinationRequestTypes.push("contractTerminationFiles");
  }

  const { mediaObjectCombinationRequests, isLoadingMediaObjectCombinationRequest, updateAction } = useMediaObjectCombinationRequest(
    settlement,
    mediaObjectCombinationRequestTypes
  );

  const navigate = useNavigate();

  const triggerUpdate = async () => {
    setIsLoadingInternal(true);
    await ApiClient.post(`/generate_requested_mediaobjects/settlement/${settlement.id}`);
    navigate(getCaseLink(settlement));
  };

  useEffect(() => {
    if (settlement) {
      let hasRequestedDocument = settlement.contract === null || settlement.contractTermination === null;
      if (!hasRequestedDocument) {
        navigate(getCaseLink(settlement));
      }
    }
  }, [settlement, navigate]);

  if (isLoadingMediaObjectCombinationRequest) {
    return (
      <Container sx={{ width: 250 }}>
        <LegalbirdLoader />
      </Container>
    );
  }

  return (
    <Layout>
      <Helmet>
        <title>Legalbird - Fehlende Dokumente</title>
      </Helmet>
      <TextContainer>
        <HintBox headline={"Fehlende Dokumente ergänzen"}>Um Ihren Fall weiter bearbeiten zu können, benötigen wir noch folgende Unterlagen von Ihnen.</HintBox>
        <Paper elevation={4} sx={requestDocumentsPageStyle.box}>
          {settlement.contract === null && (
            <FileUploadSection
              mediaObjectCombinationRequest={mediaObjectCombinationRequests.contractFiles}
              temporaryMediaObjectsFieldname={"contractFiles"}
              headline={"Arbeitsvertrag"}
              helperText={
                "Bitte laden Sie Ihren vollständigen Arbeitsvertrag hoch. Sie können hier mehrere Seiten hochladen. Bitte achten Sie darauf, dass alle Seiten des Vertrages vorliegen."
              }
              productEntity={settlement}
              productEntityId={settlement.id}
              product={"settlement"}
              updateAction={updateAction}
              setIsBlocked={setIsBlocked}
              isBlocked={isBlocked}
              fieldName={"contract"}
            />
          )}
          {settlement.contractTermination === null && (
            <FileUploadSection
              mediaObjectCombinationRequest={mediaObjectCombinationRequests.contractTerminationFiles}
              temporaryMediaObjectsFieldname={"contractTerminationFiles"}
              headline={"Kündigung"}
              helperText={"Bitte laden Sie hier das Kündigungsschreiben hoch."}
              productEntity={settlement}
              productEntityId={settlement.id}
              product={"settlement"}
              updateAction={updateAction}
              setIsBlocked={setIsBlocked}
              isBlocked={isBlocked}
              fieldName={"contractTermination"}
            />
          )}
          <br />
          <br />
          <ButtonLoading
            onClick={triggerUpdate}
            color="primary"
            variant="contained"
            disabled={false}
            fullWidth={true}
            type={"submit"}
            isLoading={isLoadingInternal}
          >
            Dokumente Hochladen
          </ButtonLoading>
        </Paper>
      </TextContainer>
    </Layout>
  );
};

export default RequestDocumentsPage;
