/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

const navigationMenuMobileStyle = {
  subMenu: {
    paddingLeft: "2rem",
  },
  logoContainer: {
    margin: "1rem 0",
  },
};

export default navigationMenuMobileStyle;
