import React from "react";
import mainTeaserStyle from "./mainTeaserStyle";
import { Box, Button, Grid } from "@mui/material";
import manWithPhone from "../../assets/img/teaser/homepage/Client_with_Smartphone.svg";
import manWithPhoneDark from "../../assets/img/teaser/homepage/Client_with_Smartphone_dark.svg";
import Typography from "@mui/material/Typography";
import ThemedIllustration from "../ThemedIconSwitch/ThemedIllustration";
import { useCustomer } from "../../provider/Customer/CustomerProvider";

type MainTeaserProps = {
  handleOpenLoginLayer: () => void;
};

export default function MainTeaser({ handleOpenLoginLayer }: MainTeaserProps) {
  const { isLoggedIn } = useCustomer();

  return (
    <Box sx={mainTeaserStyle.mainTeaserContainer}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography variant={"h1"} sx={mainTeaserStyle.headline}>
            Recht leicht gemacht
          </Typography>
          <Typography color={"textSecondary"} sx={mainTeaserStyle.subText} variant={"subtitle1"}>
            Wir helfen Ihnen einfach, schnell und professionell. Egal wann und wo!
          </Typography>
          {!isLoggedIn && (
            <Button sx={mainTeaserStyle.button} variant={"contained"} color={"primary"} onClick={handleOpenLoginLayer}>
              Jetzt starten
            </Button>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <ThemedIllustration lightIllustration={manWithPhone} darkIllustration={manWithPhoneDark} alt={"man with phone"} />
        </Grid>
      </Grid>
    </Box>
  );
}
