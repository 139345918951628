import commonJobsStyles from "../../../Jobs/commonJobsStyles";

const teamSectionStyle = {
  ...commonJobsStyles,
  headline: {
    textAlign: "center",
  },
};

export default teamSectionStyle;
