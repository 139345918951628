/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import { modalContainer } from "../../../../theme/commonStyle";
import phoneIconSvg from "../../../../assets/icons/generic/IconPhone";
import emailIconSvg from "../../../../assets/icons/generic/IconEmail";
import { Theme } from "@mui/material";

const navigationBarStyle = {
  toolBar: {
    padding: 0,
  },
  navigationContainer: {
    height: "4.8rem",
  },
  navigationItem: {
    fontSize: "1rem",
    fontWeight: 300,
    padding: "8px",
  },
  navigationSubList: {
    padding: "0px 15px 0px 40px",
  },
  active: {
    fontWeight: "bold",
  },
  icon: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    minWidth: "40px",
    minHeight: "40px",
    padding: 0,
    backgroundSize: { xs: 60, mx: "80%" },
  },
  phoneIcon: (theme: Theme) => ({
    backgroundImage: "url(" + phoneIconSvg + ")",
    [theme.breakpoints.up("md")]: {
      marginLeft: "1rem",
    },
  }),
  mailIcon: (theme: Theme) => ({
    backgroundImage: "url(" + emailIconSvg + ")",
    [theme.breakpoints.up("md")]: {
      marginLeft: "1rem",
    },
  }),
  additionalNavigation: {
    textAlign: "left",
  },
  appResponsive: {
    marginTop: "20px",
  },
  dividerBelow: {
    borderBottom: "1px solid #ddd",
  },
  burgerContainer: {
    textAlign: "right",
  },
  modalContainer,
  drawer: {
    zIndex: 1400,
  },
};

export default navigationBarStyle;
