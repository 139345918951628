/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import Layout from "../../../../Layouts/Layout";
import ServiceSection from "../../../../../components/Sections/Generic/ServiceSection/ServiceSection";
import Container from "../../../../../components/Container/Container";
import DoubleDivider from "../../../../../components/DoubleDivider/DoubleDivider";
import FaqSection from "../../../../../components/Sections/Divorce/FaqSection/FaqSection";
import KindsOfDivorceSection from "../../../../../components/Sections/Divorce/KindsOfDivorceSection/KindsOfDivorceSection";
import AdvantagesAgreedSection from "../../../../../components/Sections/Divorce/AdvantagesAgreedSection/AdvantagesAgreedSection";
import ProcedureDivorceSection from "../../../../../components/Sections/Divorce/ProcedureDivorceSection/ProcedureDivorceSection";
import AttorneySection from "../../../../../components/Sections/Divorce/DivorceAttorneySection/AttorneySection";
import TeamSection from "../../../../../components/Sections/Generic/TeamSection/TeamSection";
import GuidebookSection from "../../../../../components/Sections/Divorce/GuidebookDivorceSection/GuidebookDivorceSection";
import KindsOfDivorceLinkedSection from "../../../../../components/Sections/Divorce/KindsOfDivorceLinkedSection/KindsOfDivorceLinkedSection";
import DivorceAgreementSection from "../../../../../components/Sections/Divorce/DivorceAgreementSection/DivorceAgreementSection";
import AdvantagesLegalbirdAttorneySection from "../../../../../components/Sections/Generic/AdvantagesLegalbirdAttorneySection/AdvantagesLegalbirdAttorneySection";
import PartnerLogoSection from "../../../../../components/Sections/Generic/PartnerLogoSection/PartnerLogoSection";
import DivorceForFreeSection from "../../../../../components/Sections/Divorce/DivorceForFreeSection/DivorceForFreeSection";
import SettlementTimeSection from "../../../../../components/Sections/Settlement/SettlementTimeSection/SettlementTimeSection";
import TextContainer from "../../../../../components/Container/TextContainer";
import SettlementCheckin from "../../../../../components/Checkin/CalculateSettlementCheckin/CalculateSettlementCheckin";
import LoggedInCheckin from "../../../../../components/Checkin/LoggedInCheckin/LoggedInCheckin";
import DivorcePlannerSection from "../../../../../components/Sections/Divorce/DivorcePlannerSection/DivorcePlannerSection";
import TrustSection from "../../../../../components/Sections/Generic/TrustSection/TrustSection";
import NoWinNoFeeSection from "../../../../../components/Sections/Settlement/NoWinNoFeeSection/NoWinNoFeeSection";
import CoronaInfo from "../../../../../components/CoronaInfo/CoronaInfo";
import PartnersSection from "../../../../../components/Sections/Generic/PartnersSection/PartnersSection";
import ServiceTeaser from "../../../../../components/Teaser/ServiceTeaser";
import GreyToTransparentDivider from "../../../../../components/BackgroundWrapper/GreyToTransparentDivider";

const LpModules = () => {
  return (
    <Layout minimal={true}>
      <AttorneySection />
      <ProcedureDivorceSection />
      <DoubleDivider />
      <Container>
        <LoggedInCheckin
          checkinHeading={"Wir führen Sie Schritt für Schritt durch Ihre Scheidung"}
          buttonLink={"/servicewelt"}
          bullet1={"Kostenloses Erstgespräch"}
          bullet2={"Scheidungskosten berechnen"}
          bullet3={"Scheidungsantrag stellen"}
          idPrefix={"top"}
          color={"grey"}
        />
      </Container>
      <TextContainer>
        <SettlementCheckin idPrefix={"xxx"} />
      </TextContainer>
      <KindsOfDivorceSection />
      <KindsOfDivorceLinkedSection />
      <AdvantagesAgreedSection />
      <TrustSection />
      <DoubleDivider />
      <GuidebookSection />
      <DoubleDivider />
      <DivorceAgreementSection />
      <DoubleDivider />
      <AdvantagesLegalbirdAttorneySection />
      <DoubleDivider />
      <PartnerLogoSection partnerName={"advocard"} />
      <DoubleDivider />
      <DivorceForFreeSection />
      <DoubleDivider />

      <ServiceTeaser />
      <GreyToTransparentDivider />
      <DivorcePlannerSection />
      <DoubleDivider />
      <TeamSection headline={"Sie möchten professionell gegen Ihre Kündigung angehen?"} subHeadline={"Wir kümmern uns persönlich um Sie"} />
      <DoubleDivider />
      <NoWinNoFeeSection />
      <DoubleDivider />
      <SettlementTimeSection />
      <DoubleDivider />
      <ServiceSection />
      <TeamSection isSubsection={true} />
      <DoubleDivider />
      <FaqSection />
      <CoronaInfo />
      <PartnersSection />
      <br />
    </Layout>
  );
};

export default LpModules;
