/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import LpDivorceMeta from "./LpDivorceMeta";
import Container from "../../../../../components/Container/Container";
import DivorceCheckin from "../../../../../components/Checkin/DivorceCheckin/DivorceCheckin";
import { landingPagesList } from "../../../../../services/landingPagesList";
import { getLastPathSplit } from "../../../../../services/redirect";
import TeamSection from "../../../../../components/Sections/Generic/TeamSection/TeamSection";
import Layout from "../../../../Layouts/Layout";
import KindsOfDivorceSection from "../../../../../components/Sections/Divorce/KindsOfDivorceSection/KindsOfDivorceSection";
import AdvantagesLegalbirdAttorneySection from "../../../../../components/Sections/Generic/AdvantagesLegalbirdAttorneySection/AdvantagesLegalbirdAttorneySection";
import ProcedureDivorceSection from "../../../../../components/Sections/Divorce/ProcedureDivorceSection/ProcedureDivorceSection";
import BirdMapSection from "../../../../../components/Sections/Divorce/BirdMapSection/BirdMapSection";
import DivorcePlannerSection from "../../../../../components/Sections/Divorce/DivorcePlannerSection/DivorcePlannerSection";
import TrustSection from "../../../../../components/Sections/Generic/TrustSection/TrustSection";
import LandingPageTeaser from "../../../../../components/Teaser/LandingPageTeaser";
import ServiceSection from "../../../../../components/Sections/Generic/ServiceSection/ServiceSection";
import PartnersSection from "../../../../../components/Sections/Generic/PartnersSection/PartnersSection";
import ServiceTeaser from "../../../../../components/Teaser/ServiceTeaser";
import { divorceTeaserCards } from "../../../../../services/landingPageService";
import GreyBackgroundWrapper from "../../../../../components/BackgroundWrapper/GreyBackgroundWrapper";
import LinkedCardBox from "../../../../../components/LinkedCardBox/LinkedCardBox";
import { useLocation } from "react-router-dom";
import { useCustomer } from "../../../../../provider/Customer/CustomerProvider";

const LpDivorce = () => {
  const location = useLocation();
  const lpKey = getLastPathSplit(location.pathname);
  const keyword = (lpKey && landingPagesList[lpKey]?.keyword) ?? "Scheidung";
  const headline = (lpKey && landingPagesList[lpKey]?.headline) ?? "Scheidung online - im Guten trennen und Geld sparen";
  const { isLoggedIn } = useCustomer();

  return (
    <Layout minimal={!isLoggedIn} clickableLogo={isLoggedIn} backgroundType={"landingpage"}>
      <LpDivorceMeta keyword={keyword} />
      <LandingPageTeaser
        headline={headline}
        subheadline={"Wir begleiten Sie sicher bei Ihrer Scheidung."}
        teaserButtonLink={isLoggedIn ? "/servicewelt" : "#top-checkin"}
      >
        <LinkedCardBox linkedCards={divorceTeaserCards} />
      </LandingPageTeaser>
      <GreyBackgroundWrapper>
        {!isLoggedIn && (
          <Container>
            <DivorceCheckin idPrefix={"top"} checkinHeading={"Wir führen Sie Schritt für Schritt durch Ihre Scheidung"} />
          </Container>
        )}
        <KindsOfDivorceSection />
      </GreyBackgroundWrapper>
      <ServiceSection product={"divorce"} />
      <TeamSection isSubsection={true} />
      <BirdMapSection />
      <AdvantagesLegalbirdAttorneySection />
      <ServiceTeaser />
      <GreyBackgroundWrapper>
        <DivorcePlannerSection />
        <ProcedureDivorceSection />
      </GreyBackgroundWrapper>
      <TrustSection />
      {!isLoggedIn && (
        <Container>
          <DivorceCheckin idPrefix={"bottom"} checkinHeading={"Wir führen Sie Schritt für Schritt durch Ihre Scheidung"} />
        </Container>
      )}
      <PartnersSection />
    </Layout>
  );
};

export default LpDivorce;
