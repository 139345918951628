/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import legalbirdLogo from "../../../assets/img/logo/legalbird-logo.svg";
import legalbirdLogoWhite from "../../../assets/img/logo/legalbird-logo-white.svg";
import { Theme } from "@mui/material";

const logoStyle = {
  logo: {
    display: "flex",
    maxWidth: "10rem",
    width: "100%",
    padding: 0,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
  },
  medium: {
    minWidth: "128px",
    minHeight: "42px",
  },
  large: {
    minWidth: "256px",
    minHeight: "84px",
  },
  generic: (theme: Theme) =>
    theme.palette.mode === "dark" ? { backgroundImage: "url(" + legalbirdLogoWhite + ")" } : { backgroundImage: "url(" + legalbirdLogo + ")" },
};

export default logoStyle;
