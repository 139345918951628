/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React from "react";
import termsOfServiceStyle from "../termsOfServiceStyle";
import { RS_ADDRESS, RS_ADDRESS_LOCALITY, RS_NAME, RS_POSTAL_CODE, SERVICE_EMAIL, SERVICE_PHONE } from "../../../services/globalVars";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

const TermsOfServiceLegalbirdOS = () => {
  let number = 0;

  let subnumber = 1;

  const counter = () => {
    number++;
    return number + " ";
  };
  const subcounter = (number: number, reset: boolean = false) => {
    subnumber = reset ? 1 : subnumber + 1;
    return number + "." + subnumber + "  ";
  };

  return (
    <Box sx={termsOfServiceStyle.termsOfServicePage} id="agb">
      <Typography variant={"h1"} sx={termsOfServiceStyle.title}>
        Allgemeine Geschäftsbedingungen für Ihr Legalbird OS Konto
      </Typography>
      <Typography variant={"h3"}>Herzlich willkommen in Ihrem Legalbird-Konto</Typography>
      <p>
        Durch Einrichtung eines Nutzerkontos bei Legalbird (<b>"Legalbird-Konto"</b>) können Sie Ihre eigene digitale Akte für Rechtsangelegenheiten (
        <b>"Rechtsakte"</b>) über unsere technische Infrastruktur und web-basierende Software-Plattform (<b>"Legalbird OS"</b>) führen. Legalbird OS gibt Ihnen
        die Möglichkeit, Ihre gesamten Daten für Rechtsangelegenheiten zentral an einem Ort zu sammeln, zu strukturieren, sicher in unserer Cloud zu speichern
        und/oder zu synchronisieren und Ihrem Rechtsanwalt auf einfache Weise zugänglich zu machen (<b>"Dienste"</b>). Dabei entscheiden Sie selbst, wer in Ihre
        Rechtsakte hineinschauen darf.
      </p>
      <p>
        Diese Allgemeinen Geschäftsbedingungen (<b>"diese Bedingungen"</b> oder <b>"AGB"</b>) stellen einen Vertrag zwischen Ihnen und der {RS_NAME} (
        <b>"wir"</b> / <b>"unsere"</b> / <b>"uns"</b>) mit Sitz im {RS_ADDRESS}, {RS_POSTAL_CODE} {RS_ADDRESS_LOCALITY}, dar, der die Nutzung Ihres
        Legalbird-Kontos regelt. Ihr Zugang zu Ihrem Legalbird-Konto ist bedingt durch Ihre Annahme dieser AGB und der Datenschutzerklärung.
      </p>

      <Typography variant={"h3"}>{counter()}Anmeldung für Ihr Legalbird-Konto</Typography>
      <Typography variant={"h4"}>{subcounter(number, true)}Ihr Legalbird-Konto</Typography>
      <p>
        Bei der Anmeldung müssen Sie Ihre E-Mail-Adresse angeben und sowie Ihr Kennwort festlegen (<b>"Zugangsdaten"</b>
        ). Diese Anmeldung kann auch über Webseiten Dritter, z.B. Anwaltskanzleien oder Rechtsschutzversicherer, erfolgen (<b>"Partner-Websites"</b>). Bei der
        Registrierung über Partner-Websites werden Sie ebenfalls auf diese AGB hingewiesen und akzeptieren diese.
      </p>
      <p>
        Daraufhin wird Ihnen ein Bestätigungslink an die von Ihnen hinterlegte E-Mail-Adresse gesendet. Ihre Registrierung erfolgt, indem Sie Ihre Kontaktdaten
        durch Anklicken des Links bestätigen.
      </p>
      <p>
        Wenn Sie sich für ein Legalbird-Konto anmelden, müssen Sie uns gegenüber aktuelle, vollständige und korrekte Informationen angeben. Sollten sich nach
        erfolgter Registrierung Änderungen bei diesen Daten ergeben, sind Sie verpflichtet, uns die Änderungen unverzüglich durch Aktualisierung der Angaben in
        Ihrem Legalbird-Konto mitzuteilen. Sie müssen Ihre Zugangsdaten vertraulich behandeln und dürfen keinem Dritten erlauben, Ihr Legalbird-Konto oder
        unsere Dienste zu nutzen, es sei denn, wir erklären uns damit einverstanden. Wir dürfen jegliche Nutzung mit Ihren Zugangsdaten Ihnen selbst zuordnen,
        und Sie sind allein verantwortlich für alle Aktivitäten, die unter Ihrem Legalbird-Konto vorgenommen werden. Falls Sie den Verdacht einer unbefugten
        Nutzung Ihrer Zugangsdaten oder Ihres Legalbird-Kontos oder einer Verletzung Ihrer Datensicherheit haben, benachrichtigen Sie uns bitte umgehend über
        die folgende E-Mail-Adresse: admin@legalbird.de.
      </p>
      <p>
        <b>
          Legalbird weist ausdrücklich darauf hin, selbst weder Betreiber einer Anwaltskanzlei zu sein noch anwaltliche oder sonstige rechtliche Beratung in
          eigenem Namen durchzuführen.
        </b>
      </p>
      <Typography variant={"h4"}>{subcounter(number)}Produkte und Dienste anderer Anbieter</Typography>
      <p>
        Um unsere Dienste nutzen zu können, müssen Sie unter Umständen zusätzliche Produkte oder Dienste nutzen oder erwerben, etwa ein Gerät, einen
        Internetzugang und eine Datenverbindung. Diese müssen Sie separat erwerben und nutzen und alle damit verbundenen Kosten tragen (insbesondere die Kosten
        für Internetzugang, Textnachrichten oder andere Datenübertragungen). Unsere Dienste können es Ihnen ermöglichen, Dienste (einschließlich von
        Hilfsdiensten zum Zahlungsverkehr) und Inhalte von anderen Anbietern (<b>"Materialien anderer Anbieter"</b>) zu nutzen und zu erwerben. Derartige
        Materialien anderer Anbieter unterliegen eigenen Nutzungsbedingungen und Sie sind ausschließlich für die Einhaltung der jeweils geltenden
        Nutzungsbedingungen verantwortlich.
      </p>
      <p>
        Wenn Sie auf Materialien anderer Anbieter zugreifen oder diese nutzen oder erwerben, können hierfür andere Allgemeine Geschäftsbedingungen maßgeblich
        sein. Sie müssen sich an die maßgeblichen Allgemeinen Geschäftsbedingungen anderer Anbieter halten und alle damit verbundenen Kosten tragen. Für die
        Materialien anderer Anbieter ist allein der entsprechende Anbieter verantwortlich, wir selbst können hierfür keine Haftung übernehmen. Anspruchsgegner
        derjenigen Ansprüche, die Sie mit Bezug auf Materialien anderer Anbieter haben, ist daher allein der entsprechende Anbieter, nicht wir.
      </p>

      <Typography variant={"h3"}>{counter()}Unsere Dienste</Typography>
      <Typography variant={"h4"}>{subcounter(number, true)}Ihre persönliche Rechtsakte</Typography>
      <p>
        In Ihrem Legalbird-Konto können Sie Informationen und Dokumente mit Informationen z.B. über Ihre rechtliche und persönliche Situation, Familienstand,
        Termine, Korrespondenz z.B. mit der Gegenseite eines rechtlichen Falls, Rechtsschutzversicherungsdaten, Abrechnungsdaten oder Zahlungsdaten (
        <b>"Falldaten"</b>) sammeln, strukturieren und in Ihre Rechtsakte aufnehmen. Dadurch erhalten Sie die Möglichkeit, Ihre gesamten Rechtsdaten digital an
        einem zentralen Ort aufzubewahren, auf den Sie webbasiert von überall aus zugreifen und so relevante Daten Ihrem Rechtsanwalt leicht zugänglich machen
        können.
      </p>
      <Typography variant={"h4"}>{subcounter(number)}Zugriff und Freigabe nur durch Sie selbst</Typography>
      <p>
        <b>
          Es ist Ihre Rechtsakte, und es sind Ihre Falldaten. Sie entscheiden selbst darüber, ob und welchem Anwalt Sie Ihre Rechtsakte oder einzelne oder alle
          Falldaten zugänglich machen.
        </b>{" "}
        Eine Freigabe an eine Person, die kein Rechtsanwalt oder keine Rechtsschutzversicherung ist, ist derzeit nicht vorgesehen. Sie können Ihre Freigaben
        jederzeit widerrufen. Das Teilen von Falldaten mit Dritten erfordert, dass diese Dritte ebenfalls an Legalbird OS angebunden sind.
      </p>
      <p>
        Sie können einen Rechtsanwalt, eine Rechtsanwaltskanzlei oder eine Rechtsschutzversicherung (<b>"Kommunikationspartner"</b>) dazu ermächtigen, Ihre
        Falldaten direkt in das Legalbird OS hochzuladen und für Sie in Ihrem Legalbird-Konto verfügbar zu machen sowie die von Ihnen eingegebenen Falldaten
        einzusehen (<b>"Datenaustausch"</b>). Eine entsprechende Vereinbarung über den Datenaustausch können Sie mit Ihrem Kommunikationspartner jederzeit
        schließen oder widerrufen.
      </p>
      <p>
        Bevor Sie Ihre Vereinbarung zum Datenaustausch mit dem Kommunikationspartner geschlossen haben, haben Sie diesen - wo es rechtlich notwendig ist, z.B.
        im Falle von Rechtsanwälten - von der anwaltlichen Schweigepflicht oder sonstigen Verschwiegenheitspflichten befreit.
      </p>
      <p>
        Legalbird hat keinerlei Einfluss auf Ihre Vereinbarungen zum Datenaustausch und ist nicht verpflichtet zu prüfen, ob berufsrechtliche Vorgaben,
        insbesondere im Hinblick auf die anwaltliche Schweigepflicht, verletzt werden.
      </p>
      <Typography variant={"h4"}>{subcounter(number)}Verfügbarkeit von Inhalten und Funktionalitäten</Typography>
      <p>
        Sie können unsere Dienste und Informationen, Grafiken, Texte, Bilder, Software, Klangdateien, Videos, Kommunikationen und andere Materialien
        (einschließlich aller Metadaten), die wir durch oder im Zusammenhang mit unseren Diensten zur Verfügung stellen (<b>"Inhalte"</b>) nutzen, soweit und
        solange Sie verfügbar und erhältlich sind. Wir können weder die Verfügbarkeit bestimmter Dienste und Inhalte garantieren, noch bestimmte
        Funktionalitäten zusichern. Es kann - unter anderem aufgrund der technischen Struktur des Internet - immer vorkommen, dass es zu Verbindungsproblemen
        oder sonstigen Störungen kommt. Sie sollten insbesondere bei fallkritischen Dokumenten und Informationen immer sicherstellen, dass diese auch in anderer
        Form verfügbar sind. Bestimmte Dienste können Vorabversionen sein oder nicht korrekt funktionieren oder nicht so funktionieren wie eine Endversion
        arbeiten würde. Wir können die späteren Entwicklungsversionen von Legalbird OS deutlich ändern oder uns entscheiden, sie gar nicht auf den Markt zu
        bringen. Wir behalten uns vor, jederzeit ohne vorherige Benachrichtigung alle oder einzelne Dienste und Inhalte zu ändern, zu entfernen, zu löschen,
        einzuschränken oder unzugänglich zu machen. Wir behalten uns auch vor, die Speicherkapazität zu reduzieren oder unsere Dienste jederzeit kostenpflichtig
        zu stellen.
      </p>
      <p>
        Wir sind nicht verpflichtet, Support-Leistungen für die Nutzung unserer Dienste, Ihres Legalbird-Kontos, der Nutzerinhalte, unserer Inhalte oder der
        Materialien anderer Anbieter zu erbringen.
      </p>
      <p>
        Angaben über bestimmte Eigenschaften und/oder die Eignung unserer Dienste oder Inhalte zu einem bestimmten Verwendungszweck sind weder
        Eigenschaftszusicherungen noch Beschaffenheitsvereinbarungen im Sinne des § 434 BGB.
      </p>
      <p>
        Insbesondere geben wir keine Garantie dafür, dass unsere Dienste oder Inhalte Ihre spezifischen persönlichen Erwartungen erfüllen und auf ihrer
        Grundlage erhaltene Informationen oder Inhalte zutreffend, vollständig und verlässlich sind.
      </p>
      <p>
        Sie sind dafür verantwortlich, Sicherungskopien herzustellen für jegliche Inhalte und Informationen, die Sie in Ihrem Legalbird-Konto speichern oder
        erheben. Wir können nicht garantieren, dass Inhalte oder Informationen, die Sie speichern oder erheben, nicht entfernt, beschädigt, beeinträchtigt,
        verloren oder unzugänglich gemacht werden.
      </p>
      <p>
        Auch Anwaltskanzleien, die Ihre Falldaten über Legalbird OS und den Datenaustausch beziehen, sind gesetzlich dazu verpflichtet, bestimmte Dokumente über
        eine Dauer von bis zu dreißig Jahren aufzubewahren.
      </p>

      <Typography variant={"h3"}>{counter()}Drittnutzer</Typography>
      <p>Sie können in Ihrem Legalbird-Konto in erster Linie Ihre eigenen Falldaten hochladen und verwalten.</p>
      <p>Wenn Sie Daten Dritter hochladen, versichern Sie uns, dass dies mit Einverständnis des Dritten geschehen ist.</p>

      <Typography variant={"h3"}>{counter()}Nutzerinhalte</Typography>
      <Typography variant={"h4"}>{subcounter(number, true)}Verantwortung und Kontrolle</Typography>
      <p>
        Sie können selbst als Nutzer Materialien, insbesondere die Falldaten, in unsere Dienste einpflegen oder einstellen. Der Begriff <b>"Nutzerinhalte"</b>{" "}
        umfasst alle Falldaten und sonstigen Informationen, Grafiken, Texte, Bilder, Software, Klangdateien, Videos, Kommunikationen und andere Materialien
        (einschließlich aller Metadaten), die von Ihnen in unsere Dienste eingepflegt werden. Für Nutzerinhalte haftet allein diejenige Person, von deren
        Legalbird-Konto die Nutzerinhalte stammen. Die Nutzerinhalte werden von uns weder kontrolliert noch redigiert oder in sonstiger Weise überprüft oder
        beeinflusst.
      </p>
      <p>
        Sie versichern uns, dass den Nutzerinhalten keine Rechte Dritter entgegenstehen. Außerdem stellen Sie sicher, dass die Nutzerdaten keine Viren, Bots,
        Malware, Spyware oder Würmer enthalten.
      </p>
      <Typography variant={"h4"}>{subcounter(number)}Lizenz zur Nutzung von Nutzerinhalten</Typography>
      <p>
        <b>Ihre Nutzerinhalte gehören Ihnen. Wir beanspruchen nicht, Rechtsinhaber oder Eigentümer von Nutzerinhalten zu sein.</b> Sie erteilen uns und unseren
        Unterauftragnehmern eine beschränkte, persönliche, übertragbare, nicht ausschließliche Lizenz zur Verwendung Ihrer Nutzerinhalte ausschließlich zum
        Zweck der Erbringung unserer Dienste für Sie und nur in dem dafür erforderlichen Umfang. Ferner sind wir dazu berechtigt, die von Ihnen bereit
        gestellten Nutzerinhalte in anonymisierter bzw. aggregierter Form zur Verbesserung unserer Dienste auszuwerten und zu nutzen.
      </p>

      <Typography variant={"h3"}>{counter()}Kündigung</Typography>
      <p>
        Sie können Ihren Vertrag mit uns kündigen, indem Sie uns Ihre Kündigung in Textform an service@legalbird.de zukommen lassen. Wir behalten uns vor, diese
        Bedingungen, Ihr Legalbird-Konto oder unsere Dienste jederzeit aus wichtigem Grund zu kündigen oder zu suspendieren.
      </p>
      <p>
        Die Kündigung oder Suspendierung Ihres Legalbird-Kontos lässt Ihre Pflicht, sämtliche bis zu der Kündigung (einschließlich des Kündigungsdatums)
        entstandenen Zahlungspflichten zu erfüllen, unberührt.
      </p>
      <p>
        Sie stimmen zu, dass die Kündigung Ihres Legalbird-Kontos Ihren Zugang umgehend und dauerhaft beendet, und dass wir sämtliche Gesundheitsdaten,
        Informationen, Ordner, Kommunikationen, Voreinstellungen und Inhalte (einschließlich sämtlicher unserer Inhalte, Nutzerinhalte und Materialien anderer
        Anbieter), die auf unseren Servern im Zusammenhang mit Ihrem Legalbird-Konto gespeichert sind, nach Maßgabe der datenschutzrechtlichen Vorgaben
        unverzüglich löschen werden.
      </p>
      <p>
        Innerhalb der Schranken der untengenannten Haftungsregelungen haften wir Ihnen gegenüber nicht wegen der Kündigung oder Suspendierung Ihres
        DoctorBox-Kontos oder Ihres Zugangs zu unseren Diensten.
      </p>

      <Typography variant={"h3"}>{counter()}Haftung</Typography>
      <p>
        Im Hinblick auf die in diesen AGB beschriebenen entgeltlichen und unentgeltlichen Leistungen haftet Legalbird, gleich aus welchem Rechtsgrund, nur für
        Vorsatz, grobe Fahrlässigkeit sowie die fahrlässige Verletzung wesentlicher Vertragspflichten oder Pflichten, deren Erfüllung die Vertragsdurchführung
        überhaupt erst ermöglicht und auf deren Einhaltung Sie regelmäßig vertrauen durften (sog. Kardinalpflichten).
      </p>
      <p>Bei einfacher Fahrlässigkeit ist die Haftung auf den Ersatz des vorhersehbaren, vertragstypischen Schadens beschränkt.</p>
      <p>
        Die Haftungsbeschränkungen gemäß der vorherigen beiden Ziffern gelten nicht für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit,
        oder einer von Legalbird gegebenen Garantie sowie in Fällen von Arglist und bei Schäden nach dem Produkthaftungsgesetz.
      </p>
      <p>
        Für den Verlust von Nutzerdaten haftet Legalbird nur, wenn dieser Verlust durch angemessene Datensicherungsmaßnahmen auf Ihrer Seite nicht vermeidbar
        gewesen wäre.
      </p>
      <p>
        Legalbird betreibt die Plattform im Rahmen der technischen Möglichkeiten. Es besteht kein Anspruch auf jederzeitige fehler- und unterbrechungsfreie
        Nutzung der Plattform oder eine bestimmte Verfügbarkeit.
      </p>
      <p>
        Soweit die Haftung nach diesen AGB ausgeschlossen oder beschränkt ist, gilt dies auch für die persönliche Haftung der gesetzlichen Vertreter,
        Mitarbeiter und Erfüllungsgehilfen.
      </p>

      <Typography variant={"h3"}>{counter()}Haftungsfreistellung</Typography>
      <p>Sie stellen uns für folgende Fälle von der Haftung frei:</p>
      <ol>
        <li>Für jegliche Nutzerinhalte, die über Ihr DoctorBox-Konto eingepflegt, verbreitet, übertragen worden sind.</li>
        <li>Ihrer Nutzung unserer Dienste und Aktivitäten bei der Nutzung Ihres Legalbird-Kontos.</li>
        <li>Jeglicher Verletzung dieser Bedingungen von Ihrer Seite.</li>
        <li>Einer Verletzung von Drittrechten oder Gesetzesverletzungen von Ihrer Seite.</li>
      </ol>

      <Typography variant={"h3"}>{counter()}Legalbird OS und Updates</Typography>
      <p>
        Die Nutzung von Legalbird OS unterliegt diesen Bedingungen. Wir gewähren Ihnen ein persönliches, nicht-ausschließliches, nicht übertragbares und
        beschränktes Nutzungsrecht, Legalbird OS in Übereinstimmung mit diesen Bedingungen zu nutzen. Wir können für Legalbird OS jederzeit Updates durchführen.
        Diese Updates können Fehlerbehebungen, Funktionserweiterungen und Verbesserungen enthalten. Die Einräumung von Nutzungsrechten an Legalbird OS erfolgt
        durch Lizenzvertrag, nicht durch Verkauf. Vorbehaltlich entgegenstehender Benachrichtigungen endet Ihre Nutzungsberechtigung, wenn Sie Ihr
        Legalbird-Konto löschen oder löschen lassen oder Ihre Anmeldung für einen bezahlten Dienst kündigen, der mit Legalbird OS genutzt wird.
      </p>

      <Typography variant={"h3"}>{counter()}Benachrichtigungen für den Fall von Rechtsverletzungen</Typography>
      <p>
        Sie werden das geistige Eigentum von uns und von Dritten respektieren und die damit verbundenen Rechte nicht verletzen. Wenn Sie der Ansicht sind, dass
        Inhalte und Materialien, die über unsere Dienste zugänglich gemacht werden, die Rechte am geistigen Eigentum oder das Allgemeine Persönlichkeitsrecht
        Dritter verletzen, bitte informieren Sie uns umgehend über die folgende E-Mail-Adresse: admin@legalbird.de
      </p>
      <p>
        Wenn wir Ihre Benachrichtigung erhalten, können wir den Zugang zu rechtsverletzendem Material sperren oder löschen. Wir können jedes Legalbird-Konto
        oder das Zugangsrecht zu unseren Diensten aufgrund von Rechtsverletzungen gegenüber uns oder Dritten beenden oder kündigen.
      </p>

      <div id="widerruf">
        <Typography variant={"h3"}>{counter()}Widerrufsbelehrung</Typography>
        <p>Die folgende Widerrufsbelehrung bezieht sich auf den Widerruf von Diensten gemäß dieser AGB.</p>
        <Typography variant={"h4"}>{subcounter(number, true)} Widerruf</Typography>
        <p>
          Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag
          des Vertragsabschlusses. Um Ihr Widerrufsrecht auszuüben, müssen Sie der
        </p>
        <p>
          {RS_NAME}
          <br />
          {RS_ADDRESS}
          <br />
          {RS_POSTAL_CODE} {RS_ADDRESS_LOCALITY}
          <br />
          Tel: {SERVICE_PHONE}
          <br />
          Email: {SERVICE_EMAIL}
        </p>
        <p>
          mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen,
          informieren. Sie können dafür das beigefügte Muster-Widerrufsformular (s.u.) verwenden, das jedoch nicht vorgeschrieben ist. Zur Wahrung der
          Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
        </p>
        <Typography variant={"h4"}>{subcounter(number)}Folgen des Widerrufs</Typography>
        <p>
          Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, unverzüglich und spätestens binnen vierzehn
          Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf des Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir
          dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas Anderes
          vereinbart. In keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.
        </p>
        <p>
          Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen sollen, so haben Sie uns einen angemessenen Betrag zu zahlen, der
          dem Anteil der bis zu diesem Zeitpunkt, zu dem Sie uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrages unterrichtet haben, bereits
          erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.
        </p>

        <Typography variant={"h4"}>{subcounter(number)}Erlöschen des Widerrufsrechts</Typography>
        <p>
          <b>Das Widerrufsrecht erlischt bei einem Vertrag zur Erbringung von Dienstleistungen</b> gemäß dieser AGB, wenn Legalbird die Dienstleistung
          vollständig erbracht hat, mit der Ausführung der Dienstleistung erst begonnen hat, nachdem der Kunde dazu die ausdrückliche Zustimmung gegeben hat und
          gleichzeitig bestätigt hat, dass er sich bewusst ist, dass sein Widerrufsrecht mit Beginn der Vertragsausführung erlischt.
        </p>

        <Typography variant={"h4"}>{subcounter(number)}Muster-Widerrufsformular</Typography>
        <p>Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück an:</p>
        <p>
          {RS_NAME}
          <br />
          {RS_ADDRESS}
          <br />
          {RS_POSTAL_CODE} {RS_ADDRESS_LOCALITY}
          <br />
          Tel: {SERVICE_PHONE}
          <br />
          Email: {SERVICE_EMAIL}
        </p>
        <p>Hiermit widerrufe(n) ich/wir den von mir/uns abgeschlossenen Vertrag über den Kauf der folgenden Dienstleistungen:</p>
        <ul>
          <li>Bestellt am:</li>
          <li>Name des/der Verbraucher(s):</li>
          <li>Anschrift des/der Verbraucher(s):</li>
          <li>Datum:</li>
        </ul>

        <p>
          <b>Ende der Widerrufsbelehrung</b>
        </p>
      </div>

      <Typography variant={"h3"}>{counter()}Datenschutz</Typography>
      <p>
        Für unseren Umgang mit Ihren personenbezogenen Daten ist Ihre Zustimmung zu unserer Datenschutzerklärung maßgeblich. Wenn Sie Materialien anderer
        Anbieter im Zusammenhang mit Ihrem Legalbird-Konto nutzen, seien Sie vorsichtig. Wir sind nicht verantwortlich dafür, in welcher Weise Materialien
        anderer Anbieter auf Ihre Daten Zugriff nehmen und sie erheben, verarbeiten, bekanntgeben oder in sonstiger Weise nutzen. Sie sollten nur
        vertrauenswürdige Materialien anderer Anbieter nutzen und alle Datenschutzrichtlinien anderer Anbieter prüfen. Informationen zum Umgang mit
        personenbezogenen Daten finden sich in unserer
        <a href={"/datenschutz"}> Datenschutzerklärung</a>.
      </p>

      <Typography variant={"h3"}>{counter()}Bewertungen und Service</Typography>
      <p>
        Sie erhalten von uns innerhalb von 12 Monaten nach Einrichtung Ihres Legalbird-Kontos eine E-Mail mit der Bitte um Bewertung unserer Dienste. Sie haben
        die Möglichkeit, Legalbird durch die Vergabe von Sternen zu bewerten (<b>"Bewertungen"</b>). Es können hierbei u.a. die Qualität der Dienste, die Art
        und Weise der Durchführung sowie der Kundenservice bewertet und in einem Kommentarfeld sonstige Mitteilungen eingetragen werden.
      </p>
      <p>
        Bewertungen müssen auf nachweisbaren Tatsachen beruhen. Sie sind nach dem Gebot der Fairness sachlich zu verfassen und dürfen keinen beleidigenden,
        schmähenden, strafrechtlich relevanten oder sonst rechtswidrigen Inhalt haben (z.B. gewerbliche Schutzrechte Dritter verletzen). Bewertungen, die
        hiergegen verstoßen, werden nicht veröffentlicht bzw. gelöscht. Bewertungen stellen immer Äußerungen des Urhebers (Kunde) und keine Äußerungen von
        Legalbird dar. Legalbird prüft Bewertungen regelmäßig. Berechtigten Beschwerden über Bewertungen geht Legalbird nach und schafft - wenn notwendig -
        Abhilfe.
      </p>
      <p>
        Sie räumen Legalbird mit Absenden der Bewertung unentgeltlich die räumlich und zeitlich unbefristeten Nutzungsrechte an Ihrem Bewertungstext ein.
        Legalbird ist berechtigt, frei über die Bewertung zu verfügen und sie insbesondere für weitere Bewertungsdienste zu verarbeiten und zu verwenden, ggf.
        an Dritte weiterzugeben und sie zu veröffentlichen.
      </p>
      <p>
        Untersagt sind ungerechtfertigte Mehrfachbewertungen, Eigenbewertungen oder andere Formen des Versuchs, das Bewertungssystem verzerrend zu beeinflussen.
      </p>
      <p>
        Sie sind damit einverstanden, dass Legalbird Sie während der Benutzung der Plattform zur Erbringung der angebotenen Dienstleistung, Verbesserung und
        Erweiterung der Angebote der Plattform per E-Mail, telefonisch oder postalisch kontaktiert, um sich so über die Zufriedenheit mit den Angeboten der
        Plattform zu informieren sowie Ergänzungen, Erweiterungen und Neuigkeiten mitzuteilen.
      </p>
      <Typography variant={"h3"}>{counter()}Schlussbestimmungen</Typography>

      <Typography variant={"h4"}>{subcounter(number, true)}Salvatorische Klausel</Typography>
      <p>
        Sollte eine dieser Bestimmungen von dem jeweils zuständigen Gericht für rechtswidrig, unwirksam oder nicht durchsetzbar gehalten werden, bleiben die
        verbleibenden Bestimmungen vollständig wirksam. Überschriften dienen nur der Übersichtlichkeit und haben keine rechtliche oder vertragliche Bedeutung.
      </p>
      <p>
        Diese AGB sind vollständig und abschließend. Änderungen und Ergänzungen dieser Geschäftsbedingungen sollten, um Unklarheiten oder Streit zwischen den
        Parteien über den jeweils vereinbarten Vertragsinhalt zu vermeiden, schriftlich gefasst werden.
      </p>

      <Typography variant={"h4"}>{subcounter(number)}Abtretung</Typography>
      <p>
        Wir können unsere aus diesem Vertragsverhältnis erwachsende Rechtsposition jederzeit ganz oder teilweise an Dritte übertragen, ohne Sie darüber zu
        benachrichtigen. Sie können Ihre aus diesem Vertragsverhältnis erwachsende Rechtsposition weder übertragen noch weitergeben, ebenso wenig können Sie
        einzelne oder alle Rechte an Ihrem Legalbird-Konto oder an unseren Diensten weiterübertragen.
      </p>

      <Typography variant={"h4"}>{subcounter(number)}Verzicht</Typography>
      <p>
        Sollte wir auf einen Vertragsbruch Ihrerseits oder seitens Dritter nicht reagieren, bedeutet dies keinen Rechtsverzicht, auch nicht bezüglich weiterer
        oder ähnlicher Vertragsbrüche. Verzichtserklärungen von uns sind nur wirksam, wenn sie auf einer schriftlichen Erklärung von uns beruhen, die
        handschriftlich und mit wirksamer Stellvertretung unterzeichnet ist.
      </p>

      <Typography variant={"h4"}>{subcounter(number)}Streitbeilegung</Typography>
      <p>
        Mit der Verordnung (EU) Nr. 524/2013 hat die Europäische Kommission eine Plattform für die außergerichtliche Beilegung von Streitigkeiten geschaffen,
        die unter https://ec.europa.eu/consumers/odr/ aufrufbar ist. Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer
        Verbraucherschlichtungsstelle nicht bereit oder verpflichtet (Information gemäß § 36 des deutschen Gesetzes über die alternative Streitbeilegung in
        Verbrauchersachen (Verbraucherstreitbeilegungsgesetz - VSBG)).
      </p>

      <Typography variant={"h4"}>{subcounter(number)}Rechtswahl</Typography>
      <p>Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss der Verweisung auf andere Rechtsordnungen.</p>

      <Typography variant={"h4"}>{subcounter(number)}Gerichtsstand</Typography>
      <p>
        Der Gerichtsstand für alle im Zusammenhang mit diesen Bedingungen stehenden Rechtsstreitigkeiten (sowohl für vertragliche als auch für
        nicht-vertragliche Ansprüche) ist Köln, Deutschland.
      </p>
      <p>Stand: 15. Mai 2020</p>
    </Box>
  );
};

export default TermsOfServiceLegalbirdOS;
