/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import React, { ReactNode, useCallback } from "react";
import radioPadStyle from "./radioPadStyle";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Box, Input } from "@mui/material";

type PadProps = {
  type: "checkbox" | "radio";
  active: boolean;
  error: boolean;
  optionHintText?: string | ReactNode | null;
  labelIcon: ReactNode;
  labelIconActive?: ReactNode;
  labelText: string;
  name: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  value?: string;
};
export default function Pad({ optionHintText = "", labelIcon, labelIconActive, labelText, name, onChange, value, active, error, type }: PadProps) {
  const getPaperStyle = useCallback((active: boolean, error: boolean) => {
    if (active) {
      return radioPadStyle.activePad;
    }
    if (error) {
      return radioPadStyle.errorPad;
    }
    return {};
  }, []);

  return (
    <Box sx={radioPadStyle.wrapper}>
      <label>
        <Input
          sx={radioPadStyle.input}
          type={type}
          name={name + (type === "checkbox" ? "." + value : "")}
          value={value}
          onChange={onChange}
          inputProps={{ checked: active }}
        />
        <Paper elevation={4} sx={{ ...radioPadStyle.paper, ...getPaperStyle(active, error) }}>
          <Grid container>
            <Grid item xs={12} sx={{ ...radioPadStyle.labelIcon, ...(active ? radioPadStyle.activeColor : {}) }}>
              {active && labelIconActive ? labelIconActive : labelIcon}
            </Grid>
            <Grid item xs={12} sx={{ ...radioPadStyle.labelText, ...(active ? radioPadStyle.activeColor : {}) }}>
              {labelText}
            </Grid>
            {optionHintText && (
              <Grid item xs={12} sx={radioPadStyle.optionHintText}>
                {optionHintText}
              </Grid>
            )}
          </Grid>
        </Paper>
      </label>
    </Box>
  );
}
