/*
 * Copyright © 2025. Legalbird eine Marke der Legalbird GmbH
 */

import groupStyle from "./groupStyle";
import { Button, FormControl, FormControlTypeMap, FormHelperText, FormLabel } from "@mui/material";
import React, { ReactNode, useCallback, useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { useForm } from "../../provider/Form/FormProvider";
import { formValue } from "../../services/formServiceFunctions";
import MoreInfoIcon from "../SvgIcon/MoreInfoIcon";
import Pad from "./Pad";
import DialogModal from "../DialogModal/DialogModal";
import _get from "lodash/get";
import { useLocation, useNavigate } from "react-router-dom";
import { ValidatorType } from "../../types/ValidatorType";

type GroupOptionProps = {
  labelText: string;
  labelIcon: ReactNode;
  labelIconActive?: ReactNode;
  value: string;
  helperText?: string | ReactNode;
  optionHintText?: string | null;
};

type ResetOptionProps = {
  labelText: string;
  labelIcon: ReactNode;
  labelIconActive?: ReactNode;
  value: string;
};

type GroupProps = {
  type: "radio" | "checkbox";
  name: string;
  question: string | ReactNode;
  options: Array<GroupOptionProps>;
  moreInfoText?: string;
  modalHeaderText?: string;
  modalContent?: ReactNode;
  reset?: boolean;
  resetOption?: ResetOptionProps;
  margin?: FormControlTypeMap["props"]["margin"];
  validators?: Array<ValidatorType>;
  dependentValidationFields?: Array<any>;
};

type CheckboxGroupValue = Record<string, string>;

export default function Group({
  type,
  name,
  question,
  moreInfoText = "Weitere Informationen",
  modalContent,
  modalHeaderText = "Weitere Informationen",
  options,
  reset = false,
  resetOption = {
    labelText: "Keine der Antworten",
    labelIcon: <ClearIcon />,
    value: "none",
  },
  margin = "normal",
  validators = [],
  dependentValidationFields = [],
}: GroupProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { values, errors, setError, handleChange, registerValidators } = useForm();
  const value = formValue(values, name);
  const isValid = !_get(errors, name);

  useEffect(() => {
    registerValidators(name, validators, dependentValidationFields);
    return () => {
      registerValidators(name, [], []);
      setError(name, undefined);
    };
  }, []);

  const handleChangeInternal = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, value: string | CheckboxGroupValue) => {
      const { target } = e;
      if (type === "checkbox" && reset && typeof value !== "string" && !!value[resetOption.value] && target.checked) {
        handleChange({
          target: {
            type: "other",
            name: name,
            value: {
              ...value,
              [resetOption.value]: false,
              [target.value]: target.checked,
            },
          },
        });
      }
      handleChange(e);
      setError(name, undefined);
    },
    [type, reset, resetOption, errors]
  );

  const handleReset = useCallback(
    (value: string | CheckboxGroupValue) => {
      if (typeof value !== "string" && value[resetOption.value]) {
        handleChange({
          target: {
            type: "other",
            name: name + "." + resetOption.value,
            value: false,
          },
        });
        return;
      }
      handleChange({
        target: {
          type: "other",
          name: name,
          value: { [resetOption.value]: true },
        },
      });
    },
    [resetOption]
  );

  const isActive = useCallback(
    (optionValue: string, value: string | CheckboxGroupValue): boolean => {
      if (type === "radio") {
        return value === optionValue;
      }
      if (type === "checkbox") {
        return typeof value !== "string" ? !!value[optionValue] : false;
      }
      return false;
    },
    [type]
  );

  return (
    <FormControl component={"fieldset"} margin={margin} error={!isValid} sx={groupStyle.fieldset}>
      <FormLabel component="legend" sx={groupStyle.label}>
        {question}
        {moreInfoText && modalContent && (
          <Button
            fullWidth={true}
            variant={"text"}
            color={"primary"}
            onClick={() => {
              navigate(location.pathname, {
                state: {
                  groupModalOpen: name,
                },
              });
            }}
          >
            <MoreInfoIcon sx={groupStyle.leftIcon} /> {moreInfoText}
          </Button>
        )}
      </FormLabel>
      {options.map((option, index) => (
        <Pad
          {...option}
          key={index}
          onChange={(e) => handleChangeInternal(e, value)}
          type={type}
          name={name}
          active={isActive(option.value, value)}
          error={!isValid}
        />
      ))}
      {reset && type === "checkbox" && (
        <Pad {...resetOption} onChange={() => handleReset(value)} type={type} name={name} active={isActive(resetOption.value, value)} error={!isValid} />
      )}
      {!isValid && (
        <FormHelperText sx={groupStyle.formHelperText} error={true}>
          {_get(errors, name)}
        </FormHelperText>
      )}
      {modalContent && (
        <DialogModal open={!!(location.state && location.state.groupModalOpen === name)} onClose={() => navigate(-1)} title={modalHeaderText}>
          {modalContent}
        </DialogModal>
      )}
    </FormControl>
  );
}
