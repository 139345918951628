/*
 */

import React from "react";
import { Helmet } from "react-helmet-async";

import { gaSet } from "../../../../../services/cookieService";
import { LpMetaProps } from "../../../../../types/LpMetaProps";

const LpDivorceMeta = ({ keyword }: LpMetaProps) => {
  const title = keyword + " bei Legalbird | schnell & professionell!";
  gaSet({ title: title });

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content="Reichen Sie Ihre Scheidung online ein - schnell, einfach und nirgendwo günstiger!" />
      <meta name="keywords" content="Scheidung, Scheidung online, Scheidung einreichen, scheiden lassen, Scheidungskosten" />
      <meta name="robots" content="noindex,nofollow" />
      <meta property="og:title" content={title} />
      <meta property="og:locale" content="de_DE" />
      <meta property="og:type" content="website" />
      <meta property="og:description" content="Reichen Sie Ihre Scheidung online ein - schnell, einfach und nirgendwo günstiger!" />
    </Helmet>
  );
};

export default LpDivorceMeta;
