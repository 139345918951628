import { useLocation } from "react-router-dom";
import { getQueryParam } from "../../../services/urlParamService";
import React, { useEffect, useMemo, useRef, useState } from "react";
import LegalbirdLoader from "../../../components/ContentLoader/LegalbirdLoader";
import Layout from "../../Layouts/Layout";
import Container from "../../../components/Container/Container";
import HintBox from "../../../components/Box/HintBox/HintBox";
import Error404Page from "../Generic/Error404/Error404Page";
import Feedback from "../../../components/Feedback/Feedback";
import _isError from "lodash/isError";
import _isEmpty from "lodash/isEmpty";
import { useMutation } from "@tanstack/react-query";
import { createResource } from "../../../services/reactQuery/reactQueryService";
import { CustomerFeedback } from "../../../types/Entities/CustomerFeedback";

export default function FeedbackPage() {
  const location = useLocation();
  const createMutation = useMutation(createResource);
  const initialPostTriggered = useRef(false);

  const [feedbackEntity, setFeedbackEntity] = useState<CustomerFeedback | null>(null);

  const [hasError, setHasError] = useState(false);
  const searchLocation = location.search;

  const saveFeedback = async (data: Partial<CustomerFeedback>) => {
    createMutation.mutateAsync({ uri: "/feedback", data: data }).then(
      (result) => setFeedbackEntity(result),
      () => setHasError(true)
    );
  };

  useEffect(() => {
    const hash = getQueryParam(searchLocation, "h");
    if (hash && !_isEmpty(hash) && !initialPostTriggered.current) {
      saveFeedback({ hash });
      initialPostTriggered.current = true;
    }
  }, [searchLocation]);

  const feedbackHeadline = useMemo(() => {
    if (!feedbackEntity) {
      return "";
    }
    if (!!feedbackEntity.feedbackDateTime) {
      return "Vielen Dank für Ihre Bewertung!";
    }
    return feedbackEntity.source === "footer" ? "Wie hilfreich war diese E-Mail?" : "Wie gefällt Ihnen Legalbird?";
  }, [feedbackEntity]);

  if (hasError || _isError(feedbackEntity)) {
    return <Error404Page />;
  }

  if (!feedbackEntity) {
    return (
      <Layout>
        <Container sx={{ width: 250 }}>
          <LegalbirdLoader />
        </Container>
      </Layout>
    );
  }

  return (
    <Layout>
      <Container>
        <HintBox headline={feedbackHeadline}>
          <Feedback feedbackEntity={feedbackEntity} saveFeedback={saveFeedback} />
        </HintBox>
      </Container>
    </Layout>
  );
}
